import React, {
  Fragment,
  useEffect,
  useReducer,
  useState,
  useRef,
} from "react";
import { Button, Modal, Tab, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import toast from "react-hot-toast";
import CustomSelect from "./CustomSelect";
import PhoneInput from "react-phone-input-2";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import {
  editLabProfile,
  editPharmaProfile,
  getLabProfile,
  getPharmaProfile,
} from "../../services/AsyncFunctions";
import {
  zodValidationSchemaEditProfile,
  zodValidationSchemaEditProfilePharma,
} from "../../utils/zodschema";
import { profileUpdateAction } from "../../store/actions/AuthActions";

const initialState = false;
const reducer = (state, action) => {
  switch (action.type) {
    case "sendMessage":
      return { ...state, sendMessage: !state.sendMessage };
    case "postModal":
      return { ...state, post: !state.post };
    case "linkModal":
      return { ...state, link: !state.link };
    case "cameraModal":
      return { ...state, camera: !state.camera };
    case "replyModal":
      return { ...state, reply: !state.reply };
    default:
      return state;
  }
};

const AppProfilePharma = () => {
  const navigate = useHistory();
  const [state] = useReducer(reducer, initialState);
  const [profileData, setProfileData] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  const [locationDetails, setLocationDetails] = useState({
    lat: null,
    lng: null,
    label: "",
  });

  async function getProfileData() {
    setLoadingData(true);
    try {
      const response = await getPharmaProfile();
      setProfileData(response.data?.data);
      setLoadingData(false);
    } catch (error) {
      console.log(error, "error");
      setLoadingData(false);
    }
  }
  const [googleAddress, setGoogleAddress] = useState("");

  const [code, setCode] = useState("+1");
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(zodValidationSchemaEditProfilePharma),
  });
  const dispatch = useDispatch();

  const formatTime = (time) => {
    if (!time) return "";
    const [hour, minute] = time.split(":").map(Number);
    const formattedHour = hour < 10 ? `0${hour}` : `${hour}`;
    return `${formattedHour}:${minute.toString().padStart(2, "0")}`;
  };

  useEffect(() => {
    if (profileData) {
      const newProfileData = {
        phoneNumber: profileData?.phoneNumber || "",
        countryCode: profileData?.countryCode,
        pharmacyName: profileData?.pharmacyName,
        startTime: formatTime(profileData?.openingHours?.startTime),
        endTime: formatTime(profileData?.openingHours?.closeTime),
      };

      setGoogleAddress(profileData?.address);
      setLocationDetails({
        lat: profileData?.location?.coordinates[1],
        lng: profileData?.location?.coordinates[0],
        label: profileData?.address,
      });

      setCode(profileData?.countryCode);
      reset(newProfileData);
    }
  }, [profileData, reset]);

  const handleAddress = async (place) => {
    try {
      setGoogleAddress(place.label);
      const results = await geocodeByAddress(place.label);
      const latLng = await getLatLng(results[0]);

      const newLocationDetails = {
        lat: latLng.lat,
        lng: latLng.lng,
        label: place.label,
      };

      setLocationDetails(newLocationDetails);
    } catch (error) {
      console.error("Error getting address details:", error);
    }
  };

  const onSubmit = async (data) => {
    const { pharmacyName, phoneNumber, startTime, endTime } = data;
    const formData = {
      pharmacyName: pharmacyName,
      phoneNumber: phoneNumber + "",
      countryCode: code,
      address: locationDetails.label,
      lat: locationDetails.lat ?? 0,
      long: locationDetails.lng ?? 0,
      openingHours: {
        startTime: startTime,
        closeTime: endTime,
      },
    };

    setIsLoading(true);

    try {
      let response = await editPharmaProfile(formData);
      if (response.status === 200) {
        toast.success("Edit successfull", { duration: 1000 });
        localStorage.setItem("anyTimeHospitalName", pharmacyName);
        navigate.push("/");
        reset();
        dispatch(profileUpdateAction(true));
      }
    } catch (error) {
      // console.log(error.response.data.message);
      // toast.error(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getProfileData();
  }, []);

  return (
    <Fragment>
      <div className="row">
        <div className="col-xl-8">
          <div className="card">
            <div className="card-body">
              <div className="profile-tab">
                <div className="custom-tab-1">
                  <Tab.Container defaultActiveKey="About">
                    <Nav as="ul" className="nav nav-tabs">
                      <Nav.Item as="li" i className="nav-item">
                        <Nav.Link to="#about-me" eventKey="About">
                          About Me
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li" className="nav-item">
                        <Nav.Link to="#profile-settings" eventKey="Setting">
                          Setting
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane id="about-me" eventKey="About">
                        <div className="profile-about-me pt-3">
                          <div className="profile-personal-info">
                            <h4 className="text-primary mb-4">
                              Personal Information
                            </h4>
                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500">
                                  Pharma Name
                                  <span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-9">
                                <span>
                                  {loadingData
                                    ? "Loading..."
                                    : profileData?.pharmacyName}
                                </span>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500">
                                  Phone Number
                                  <span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-9">
                                <span>
                                  {loadingData
                                    ? "Loading..."
                                    : profileData?.phoneNumber}
                                </span>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500">
                                  Email<span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-9">
                                <span>
                                  {loadingData
                                    ? "Loading..."
                                    : profileData?.email}
                                </span>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500">
                                  Address<span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-9">
                                <span>
                                  {loadingData
                                    ? "Loading..."
                                    : profileData?.address}
                                </span>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500">
                                  Start Time
                                  <span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-9">
                                <span>
                                  {loadingData
                                    ? "Loading..."
                                    : profileData?.openingHours?.startTime}
                                </span>
                              </div>
                            </div>

                            <div className="row mb-2">
                              <div className="col-3">
                                <h5 className="f-w-500">
                                  End Time
                                  <span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-9">
                                <span>
                                  {loadingData
                                    ? "Loading..."
                                    : profileData?.openingHours?.closeTime}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane id="profile-settings" eventKey="Setting">
                        <div className="pt-3">
                          <div className="settings-form">
                            <h4 className="text-primary">Account Setting</h4>
                            <div className="auth-form">
                              <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group">
                                  <label className="mb-1 mt-3 ">
                                    <strong>Pharma Name</strong>
                                  </label>

                                  <input
                                    id="name"
                                    type="text"
                                    className="form-control global-input-field"
                                    {...register("pharmacyName")}
                                  />
                                </div>
                                {errors.name && (
                                  <div className="text-danger fs-12">
                                    {errors.pharmacyName?.message}
                                  </div>
                                )}

                                <div className=" form-group">
                                  <label className="mb-1 mt-3 ">
                                    <strong>Address</strong>
                                  </label>
                                  <GooglePlacesAutocomplete
                                    className=""
                                    apiKey={
                                      "AIzaSyA_kz_OxgMPwWS7AEWRVUHSLSvqYFlgZAc"
                                    }
                                    minLengthAutocomplete={3}
                                    selectProps={{
                                      value: {
                                        label: googleAddress,
                                        value: googleAddress,
                                      },
                                      onChange: (place) => {
                                        handleAddress(place);
                                      },
                                    }}
                                    onLoadFailed={() => toast.error("Error")}
                                  />
                                </div>

                                <div className="form-group">
                                  <label className="mb-1 mt-3 ">
                                    <strong>Opening Hours</strong>
                                  </label>
                                  <div
                                    className="d-flex"
                                    style={{ justifyContent: "space-between" }}
                                  >
                                    <div
                                      className="mr-3"
                                      style={{ width: "100%" }}
                                    >
                                      <input
                                        type="time"
                                        className="form-control"
                                        {...register("startTime")}
                                      />
                                      {errors.startTime && (
                                        <div className="text-danger fs-12">
                                          {errors.startTime?.message}
                                        </div>
                                      )}
                                    </div>
                                    <div style={{ width: "100%" }}>
                                      <input
                                        type="time"
                                        className="form-control"
                                        {...register("endTime")}
                                      />
                                      {errors.endTime && (
                                        <div className="text-danger fs-12">
                                          {errors.endTime?.message}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>

                                <div className="form-group">
                                  <label className="mb-1 mt-3 MB-10">
                                    <strong>Phone number</strong>
                                  </label>
                                  <div className="d-flex">
                                    <div className="relative">
                                      <PhoneInput
                                        country={"us"}
                                        enableSearch={true}
                                        value={code}
                                        onChange={(phone) => setCode(phone)}
                                      />
                                    </div>

                                    <div className="w-100">
                                      <input
                                        id="phoneNumber"
                                        type="number"
                                        className="form-control global-input-field  sign-phone"
                                        {...register("phoneNumber", {
                                          valueAsNumber: true,
                                        })}
                                        style={{ paddingLeft: "111px" }}
                                      />
                                    </div>
                                  </div>
                                  {errors.phoneNumber && (
                                    <div className="text-danger fs-12">
                                      {errors.phoneNumber?.message}
                                    </div>
                                  )}
                                </div>
                                <div className="text-center mt-4">
                                  <button
                                    type="submit"
                                    disabled={isLoading}
                                    className="btn btn-primary btn-block"
                                  >
                                    {isLoading ? "Processing..." : "Submit"}
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* send Modal */}
      <Modal
        className="modal fade"
        show={state.sendMessage}
        onHide={() => dispatch({ type: "sendMessage" })}
        centered
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Send Message</h5>
            <Button
              variant=""
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => dispatch({ type: "sendMessage" })}
            >
              <span>×</span>
            </Button>
          </div>
          <div className="modal-body">
            <form
              className="comment-form"
              onSubmit={(e) => {
                e.preventDefault();
                dispatch({ type: "sendMessage" });
              }}
            >
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label htmlFor="author" className="text-black font-w600">
                      {" "}
                      Name <span className="required">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue="Author"
                      name="Author"
                      placeholder="Author"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group mb-3">
                    <label htmlFor="email" className="text-black font-w600">
                      {" "}
                      Email <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue="Email"
                      placeholder="Email"
                      name="Email"
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <label htmlFor="comment" className="text-black font-w600">
                      Comment
                    </label>
                    <textarea
                      rows={4}
                      className="form-control"
                      name="comment"
                      placeholder="Comment"
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <input
                      type="submit"
                      value="Post Comment"
                      className="submit btn btn-primary"
                      name="submit"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      {/* Post Modal */}
      <Modal
        show={state.post}
        className="modal fade"
        id="postModal"
        onHide={() => dispatch({ type: "postModal" })}
        centered
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Post</h5>
            <Button
              variant=""
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => dispatch({ type: "postModal" })}
            >
              <span>×</span>
            </Button>
          </div>
          <div className="modal-body">
            <textarea
              name="textarea"
              id="textarea"
              cols={30}
              rows={5}
              className="form-control mb-2 bg-transparent"
              placeholder="Please type what you want...."
              defaultValue={""}
            />
            <Link
              className="btn btn-primary btn-rounded mt-1"
              to="/app-profile"
            >
              Post
            </Link>
          </div>
        </div>
      </Modal>
      {/* Link Modal */}
      <Modal
        show={state.link}
        className="modal fade post-input"
        id="linkModal"
        onHide={() => dispatch({ type: "linkModal" })}
        centered
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Social Links</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => dispatch({ type: "linkModal" })}
            >
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Link className="btn-social me-1 facebook" to="/app-profile">
              <i className="fa fa-facebook-f" />
            </Link>
            <Link className="btn-social me-1 google-plus" to="/app-profile">
              {" "}
              <i className="fa fa-google-plus" />
            </Link>
            <Link className="btn-social me-1 linkedin" to="/app-profile">
              <i className="fa fa-linkedin" />
            </Link>
            <Link className="btn-social me-1 instagram" to="/app-profile">
              {" "}
              <i className="fa fa-instagram" />
            </Link>
            <Link className="btn-social me-1 twitter" to="/app-profile">
              <i className="fa fa-twitter" />
            </Link>
            <Link className="btn-social me-1 youtube" to="/app-profile">
              <i className="fa fa-youtube" />
            </Link>
            <Link className="btn-social whatsapp" to="/app-profile">
              <i className="fa fa-whatsapp" />
            </Link>
          </div>
        </div>
      </Modal>
      {/* Camera Modal */}
      <Modal
        show={state.camera}
        className="modal fade"
        id="cameraModal"
        onHide={() => dispatch({ type: "cameraModal" })}
        centered
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Upload images</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => dispatch({ type: "cameraModal" })}
            >
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="input-group custom_file_input mb-3">
              <span className="input-group-text">Upload</span>
              <div className="form-file">
                <input type="file" className="form-file-input form-control" />
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* Reply Modal */}
      <Modal
        show={state.reply}
        className="modal fade"
        id="replyModal"
        onHide={() => dispatch({ type: "replyModal" })}
        centered
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Post Reply</h5>
            <button
              type="button"
              className="close"
              onClick={() => dispatch({ type: "replyModal" })}
            >
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <form>
              <textarea className="form-control" rows="4">
                Message
              </textarea>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger light"
              onClick={() => dispatch({ type: "replyModal" })}
            >
              Close
            </button>
            <button type="button" className="btn btn-primary">
              Reply
            </button>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default AppProfilePharma;
