// import axios from "axios";
// const axiosInstance = axios.create({
//   baseURL: `http://54.66.218.182:3003/api/v1/`,
// });
// export default axiosInstance;

import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://api.anytimehospital.com",
});


axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("_anytime_pharm_token_");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("_anytime_pharm_token_");
      window.location.replace("/login");
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
