import React, { useState, useEffect } from "react";
import { Card, Col, Modal, Button } from "react-bootstrap";
import toast from "react-hot-toast";
import { HiOutlineTrash } from "react-icons/hi";
import { getLabTestSuggention } from "../../../services/AsyncFunctions";

function CreateInvoiceTestLab({ onHide, show, testData, setTestData }) {
  const [testName, setTestName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const fetchSuggestions = async (query) => {
    try {
      const response = await getLabTestSuggention(query);
      setSuggestions(response.data?.data || []);
      const data = await response.json();
      setShowSuggestions(true);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (testName.length >= 2) {
        fetchSuggestions(testName);
      } else {
        setSuggestions([]);
        setShowSuggestions(false);
      }
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [testName]);

  const handleSuggestionClick = (suggestion) => {
    setTestName(suggestion.name);
    setShowSuggestions(false);
  };

  async function submit() {
    onHide();
  }

  function addTestoInvoiceList(e) {
    e.preventDefault();
    if (!testName) {
      toast.error("Test name is required");
      return;
    } else if (!description) {
      toast.error("Description is required");
      return;
    } else if (!price || price <= 0 || !Number.isInteger(Number(price))) {
      toast.error("Price is required");
      return;
    } else {
      if (testData.length == 0) {
        setTestData([
          {
            testName,
            description,
            price,
          },
        ]);
      } else {
        setTestData((prev) => [
          ...prev,
          {
            testName,
            description,
            price,
          },
        ]);
      }
      setTestName("");
      setDescription("");
      setPrice("");
    }
  }
  function removeMedicineFromList(ItemIndex) {
    const tempList = testData.filter((item, index) => ItemIndex !== index);
    setTestData([...tempList]);
  }

  return (
    <Modal
      className="modal fade"
      size="lg"
      centered
      onHide={onHide}
      show={show}
    >
      <Col>
        <Card>
          <Card.Header className="d-block">
            <div className="d-flex justify-content-between align-items-center">
              <h3>Create Invoice</h3>
              <Button variant="" className="close" onClick={() => onHide()}>
                <span style={{ fontSize: "30px" }}>&times;</span>
              </Button>
            </div>
          </Card.Header>

          <Card.Body>
            <form
              onSubmit={addTestoInvoiceList}
              className="d-flex align-items-start flex-column gap-2"
            >
              <div className="position-relative w-100">
                <input
                  type="text"
                  placeholder="Test Name"
                  value={testName}
                  onChange={(e) => setTestName(e.target.value)}
                  className="form-control mr-2"
                  name="testName"
                />
                {showSuggestions && suggestions.length > 0 && (
                  <div
                    className="position-absolute w-100 bg-white border rounded mt-1 shadow-sm"
                    style={{ 
                      zIndex: 1000,
                      maxHeight: suggestions.length > 5 ? '200px' : 'auto',
                      overflowY: suggestions.length > 5 ? 'auto' : 'visible'
                    }}
                  >
                    {suggestions.map((suggestion, index) => (
                      <div
                        key={index}
                        className="p-2 cursor-pointer hover:bg-gray-100"
                        onClick={() => handleSuggestionClick(suggestion)}
                      >
                        {suggestion.name}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <input
                type="text"
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="form-control mr-2"
                name="description"
              />
              <input
                type="text"
                placeholder="Price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                className="form-control mr-2"
                name="price"
              />
              <button className="btn btn-outline-primary" type="submit">
                Add
              </button>
            </form>
            {testData.length > 0 && (
              <ol className="">
                <div className="row mt-3">
                  <div className="col-4  pharma-card_distance invoice-table-header">
                    Test Name
                  </div>
                  <div className="col-4  pharma-card_distance invoice-table-header">
                    Description
                  </div>
                  <div className="col-2 pharma-card_distance invoice-table-header">
                    Price
                  </div>
                  <div className="col-2 pharma-card_distance invoice-table-header">
                    Action
                  </div>
                </div>
                {testData.map((item, index) => (
                  <div key={index} className="row mt-3">
                    <div className="col-4 pharma-card_distance">
                      <span className="font-weight-bold">{index + 1}.</span>{" "}
                      {item.testName}
                    </div>
                    <div className=" col-4 pharma-card_distance d-flex align-items-center">
                      <span className="">{item.description}</span>
                    </div>
                    <div className=" col-2 pharma-card_distance d-flex align-items-center">
                      <span className="">{item.price}</span>
                    </div>
                    <div
                      className=" col-2 pharma-card_distance d-flex align-items-center"
                      onClick={() => removeMedicineFromList(index)}
                    >
                      <HiOutlineTrash
                        size={"25px"}
                        color="red"
                        className="ml-2 pointer"
                      />
                    </div>
                  </div>
                ))}
              </ol>
            )}
          </Card.Body>
          <Card.Footer>
            <button className="btn btn-primary w-100" onClick={submit}>
              Add Invoice
            </button>
          </Card.Footer>
        </Card>
      </Col>
    </Modal>
  );
}

export default CreateInvoiceTestLab;
