import React, { useEffect, useState } from "react";
import { Card, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import moment from "moment";
import {
  APPOINTMENTS_EVENTS,
  getStatusValues,
  getStatuslabValues,
} from "../../utils/content";
import eventBus from "../../services/Eventbus";
import {
  changeLabOrderStatus,
  changeOrderStatus,
  getOrderDetails,
  getOrderLabDetails,
  postLabInvoiceToPatient,
} from "../../services/AsyncFunctions";
import OrderStatusStepper from "../components/OrderStatusStepper";
import Select from "react-select";
import CreateInvoice from "../components/modals/CreateInvoice";
import CreateLabInvoice from "../components/modals/CreateLabInvoice";
import Swal from "sweetalert2";
import LabOrderStatusStepper from "../components/LabOrderStatusStepper";
import addFileToS3Bucket from "../../services/Aws";
import ImageUpload from "../components/modals/ImageUpload";

function LabOrderDetails() {
  const { orderId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedFilesShowModal, setUploadedFilesShowModal] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const [showPharmacyModal, setShowPharmacyModal] = useState(false);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const orderStatus = [
    {
      label: "Received",
      value: "Receive",
      condition: orderDetails?.orderStatus.isOrderReceived,
    },
    {
      label: "Sample Collected",
      value: "sampleCollected",
      condition: orderDetails?.orderStatus.isSampleCollected,
    },

    {
      label: "Report Generated",
      value: "ReportGenerated",
      condition: orderDetails?.orderStatus.isReportGenerated,
    },
    {
      label: "Report Delivered",
      value: "reportDelivered",
      condition: orderDetails?.orderStatus.isReportDelivered,
    },
  ];

  // Filter options where the condition is false
  const filteredOrderStatus = orderStatus.filter((status) => !status.condition);

  async function fetchOrderDetails() {
    if (!orderId) {
      toast.error("Could not fetch details");
      return;
    }
    setIsLoading(true);
    try {
      let response = await getOrderLabDetails(orderId);
      console.log(response, "lab details");
      setOrderDetails(response?.data?.data);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function updateOrderStatus(status) {
    try {
      const response = await changeLabOrderStatus({
        orderId,
        status,
      });
      fetchOrderDetails();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }

  function handleStatusChange(status) {
    if (status.value === "reportDelivered") {
      if (orderDetails.reports.length > 0) {
        updateOrderStatus(status.value);
      } else {
        toast.error("Please upload report first");
      }
    } else {
      updateOrderStatus(status.value);
    }
  }
  async function reject() {
    // if (invoiceMedicineList?.length > 0) {
    const result = await Swal.fire({
      title: "Are you sure you want to reject this invoice .",
      text: "This action cannot be undone!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });

    if (!result.isConfirmed) return;
    let payload = {
      orderId: orderId,
      status: "Reject",
    };
    setLoading(true);
    try {
      const response = await postLabInvoiceToPatient(payload);
      console.log(response);
      fetchOrderDetails();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
    // }
  }

  useEffect(() => {
    fetchOrderDetails();
  }, []);

  return (
    <>
      <CreateLabInvoice
        show={showInvoiceModal}
        onHide={() => setShowInvoiceModal(false)}
        orderId={orderId}
        fetchOrderDetails={() => fetchOrderDetails()}
        list={orderDetails?.test}
      />
      <ImageUpload
        show={uploadedFilesShowModal}
        onHide={() => setUploadedFilesShowModal(false)}
        orderId={orderId}
        fetchOrderDetails={() => fetchOrderDetails()}
      />
      <div className="form-head d-flex mb-3 align-items-start">
        <div className="mr-auto w-100 d-flex align-items-center justify-content-between flex-wrap">
          <h2 className="text-black font-w600 mb-0">Order Details</h2>
          {(orderDetails?.status === "Pending" ||
            orderDetails?.status === "Awaiting") &&
            !orderDetails.isAccept &&
            orderDetails.myLab.status === "Pending" && (
              <div className="col-auto d-flex align-items-center d-flex">
                <button
                  className="btn btn-success p-2 px-3 fs-12 mr-2"
                  onClick={() => setShowInvoiceModal(true)}
                >
                  Accept
                </button>
                <button
                  className="btn bg-danger text-white p-2 px-3 fs-12"
                  onClick={reject}
                  disabled={loading}
                >
                  Reject
                </button>
              </div>
            )}
        </div>
      </div>
      {!isLoading && orderDetails && (
        <div className="row">
          {orderDetails?.type !== "offline" && (
            <Card className="col-11 col-md-5 p-3 flex-row flex-grow-1 mx-auto mb-3">
              <div className="d-flex align-items-start">
                {orderDetails?.bookingFor === "mySelf" && (
                  <img
                    src={
                      orderDetails?.user?.image
                        ? process.env.REACT_APP_BUCKET_BASE_URL +
                          orderDetails?.user?.image
                        : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                    }
                    alt=""
                    className="appointment-details-img"
                  />
                )}
                {orderDetails?.bookingFor === "familyMember" && (
                  <img
                    src={
                      orderDetails?.user?.image
                        ? process.env.REACT_APP_BUCKET_BASE_URL +
                          orderDetails?.member?.image
                        : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                    }
                    alt=""
                    className="appointment-details-img"
                  />
                )}
              </div>
              <div className="row w-100 ml-2">
                <div className="col-6">
                  <div className="">
                    <label htmlFor="" className="fs-12 mb-0">
                      Patient Name
                    </label>
                    <div className="fs-14 font-weight-bold capitalize">
                      {orderDetails?.bookingFor === "familyMember"
                        ? orderDetails?.member?.name
                        : orderDetails?.user?.name}
                    </div>
                  </div>
                  <div className="">
                    <label htmlFor="" className="fs-12 mb-0">
                      Email
                    </label>
                    <div className="fs-14 font-weight-bold capitalize">
                      {orderDetails?.bookingFor === "familyMember"
                        ? orderDetails?.member?.memberEmail
                        : orderDetails?.user?.email}
                    </div>
                  </div>
                  <div className="">
                    <label htmlFor="" className="fs-12 mb-0">
                      Doctor
                    </label>
                    <div className="fs-14 font-weight-bold capitalize">
                      {orderDetails?.doctor?.name}
                    </div>
                  </div>
                  <div className="">
                    <label htmlFor="" className="fs-12 mb-0">
                      Age
                    </label>
                    <div className="fs-14 font-weight-bold capitalize">
                      {orderDetails?.bookingFor === "familyMember"
                        ? moment().diff(
                            moment(
                              orderDetails?.member?.dateOfBirth,
                              "DD-MM-YYYY"
                            ),
                            "years"
                          )
                        : moment().diff(
                            moment(
                              orderDetails?.user?.dateOfBirth,
                              "DD-MM-YYYY"
                            ),
                            "years"
                          )}{" "}
                      Years
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="">
                    <label htmlFor="" className="fs-12 mb-0">
                      Booking For
                    </label>
                    <div className="fs-14 font-weight-bold capitalize">
                      {orderDetails?.bookingFor == "mySelf" ? "Self" : "Member"}
                    </div>
                  </div>
                  <div className="">
                    <label htmlFor="" className="fs-12 mb-0">
                      Order Type
                    </label>
                    <div className="fs-14 font-weight-bold capitalize">
                      {orderDetails?.method == "online" ? "Delivery" : "Pickup"}
                    </div>
                  </div>
                  <div className="">
                    <label htmlFor="" className="fs-12 mb-0">
                      Order Amount
                    </label>
                    <div className="fs-14 font-weight-bold capitalize">
                      {orderDetails?.myLab?.totalPrice < 1
                        ? "N/A"
                        : "$" + orderDetails?.myLab?.totalPrice}
                    </div>
                  </div>
                  <div className="">
                    <label htmlFor="" className="fs-12 mb-0">
                      Doctor Phone Number
                    </label>
                    <div className="fs-14 font-weight-bold capitalize">{`${orderDetails?.doctor?.countryCode} ${orderDetails?.doctor?.phoneNumber}`}</div>
                  </div>
                  <div className="">
                    <label htmlFor="" className="fs-12 mb-0">
                      User Phone Number
                    </label>
                    <div className="fs-14 font-weight-bold capitalize">
                      {" "}
                      {orderDetails?.bookingFor === "familyMember"
                        ? `+${orderDetails?.member?.countryCode} ${orderDetails?.member?.phoneNumber}`
                        : `+${orderDetails?.user?.countryCode} ${orderDetails?.user?.phoneNumber}`}
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          )}
          <div className="col-12 col-md-7 flex-grow-1">
            {(orderDetails?.status == "Accepted" ||
              orderDetails?.status == "Completed") &&
              orderDetails?.type !== "offline" &&
              orderDetails?.isAccept && (
                <div className="d-flex w-100 ">
                  <Card className="p-3  flex-grow-1">
                    <div className="d-flex align-items-center justify-content-between">
                      <h4>Order Status</h4>
                      <Select
                        className="flex-grow-1 order-status-select pharma-card_distance"
                        options={filteredOrderStatus}
                        id="status-select"
                        placeholder="Update Status"
                        onChange={(value) => handleStatusChange(value)}
                      />
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <LabOrderStatusStepper
                        currentStatusArray={getStatuslabValues(
                          orderDetails?.orderStatus
                        )}
                      />
                    </div>
                  </Card>
                </div>
              )}
            {(orderDetails?.status == "Accepted" ||
              orderDetails?.status == "Completed") &&
              orderDetails?.type !== "offline" &&
              orderDetails?.isAccept && (
                <div className="d-flex w-100 ">
                  <Card className="p-3  flex-grow-1">
                    <div className="d-flex align-items-center justify-content-between">
                      <h4>Upload Image</h4>
                      <button
                        className="btn btn-outline-primary p-2 px-3 fs-12"
                        onClick={() => setUploadedFilesShowModal(true)}
                      >
                        Add Report <i className="flaticon-381-plus"></i>{" "}
                      </button>
                    </div>
                    <ul
                      className="list-group overflow-auto mt-2"
                      style={{ maxHeight: "200px", width: "100%" }}
                    >
                      {orderDetails?.reports.map((fileUrl, index) => (
                        <li
                          key={index}
                          className="list-group-item d-flex justify-content-between align-items-center"
                          style={{ wordWrap: "break-word" }}
                        >
                          <a
                            href={fileUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-truncate"
                            style={{ maxWidth: "70%" }}
                          >
                            {fileUrl}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </Card>
                </div>
              )}

            <div className="d-flex w-100 ">
              <Card className="p-3  flex-grow-1">
                <div className="d-flex align-items-center justify-content-between">
                  <h4>My Lab Test</h4>
                  {orderDetails?.myLab?.status === "Accepted" && (
                    <button className="btn btn-success p-2 px-3 fs-12 mr-2">
                      {orderDetails?.myLab?.status}
                    </button>
                  )}
                  {orderDetails?.myLab?.status === "Rejected" && (
                    <button className="btn bg-danger text-white p-2 px-3 fs-12">
                      {orderDetails?.myLab?.status}
                    </button>
                  )}
                </div>
                {orderDetails?.myLab?.invoice.length > 0 &&
                  orderDetails?.myLab?.invoice.map((item, index) => {
                    return (
                      <div
                        key={item._id}
                        className="d-flex align-items-start flex-column mt-3"
                      >
                        <div className="pharma-card_distance mb-2">
                          <span className="font-weight-bold">{index + 1}.</span>{" "}
                          <span>{item.testName}</span> ${item.price}
                        </div>
                      </div>
                    );
                  })}
              </Card>
            </div>
            <div className="d-flex w-100 ">
              <Card className="p-3  flex-grow-1">
                <h4>Test</h4>
                {orderDetails?.test &&
                  orderDetails?.test.map((item, index) => (
                    <div
                      key={item._id}
                      className="d-flex align-items-start flex-column mt-3"
                    >
                      <div className="pharma-card_distance mb-2">
                        <span className="font-weight-bold">{index + 1}.</span>
                        {item.name}
                      </div>
                      <p>{item.description}</p>
                    </div>
                  ))}
              </Card>
            </div>
          </div>
        </div>
      )}
      {isLoading && (
        <div className="w-100 h-50 d-flex align-items-center justify-content-center">
          <Spinner animation="border" variant="info" />
        </div>
      )}
    </>
  );
}

export default LabOrderDetails;
