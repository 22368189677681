import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import AddMedicine from "../modal/AddMedicine";
import { deleteMedicine, getInventory } from "../../services/AsyncFunctions";
import toast from "react-hot-toast";
import moment from "moment";
// import { Spinner } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  setTransferInvertoryAction,
  setTransferInvertoryActionNull,
} from "../../store/actions/InventoryAction";
import { Spinner } from "react-bootstrap";
import {
  Card,
  Table,
} from "react-bootstrap";

const InventoryManagement = () => {
  const dispatch = useDispatch();
  const [id, setid] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showAddMedicineModal, setShowAddMedicineModal] = useState(false);
  const [inventoryData, setInvetoryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchString, setSearchString] = useState("");
  const [pageCount,setPageCount]=useState(1)
  const [total,setTotal]=useState(1)
  const [stockFilter, setStockFilter] = useState('all');
  const [lowStockThreshold] = useState(10);

  async function fetchInventory() {
    setLoading(true);
    try {
      const response = await getInventory(searchString,pageCount-1);
      console.log(response);
      setInvetoryData(response.data.data.medicines);
      setTotal(response.data.data.total);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if(searchString){
      const delayDebounceFn = setTimeout(() => {
        fetchInventory();
      }, 500);
     return () => clearTimeout(delayDebounceFn);
    }else {
      fetchInventory();
    }
  }, [searchString,pageCount]);

  const editHandler = (data) => {
    setShowAddMedicineModal(true);
    dispatch(setTransferInvertoryAction(data));
  };

  const deleteHandler = async (id) => {
    try {
      setDeleteLoading(true);
      setid(id);
      const response = await deleteMedicine(id);
      fetchInventory();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setDeleteLoading(false);
    }
  };

  const gotoPage =(page)=>{
    setPageCount(page)
  }

  const previousPage =()=>{
    setPageCount(pageCount-1)
  }

  const nextPage =()=>{
    setPageCount(pageCount+1)
  }

  const getFilteredInventory = () => {
    return inventoryData.filter(item => {
      switch(stockFilter) {
        case 'in-stock':
          return item.stockCount > lowStockThreshold;
        case 'low-stock':
          return item.stockCount <= lowStockThreshold && item.stockCount > 0;
        case 'out-of-stock':
          return item.stockCount === 0;
        default:
          return true;
      }
    });
  };

  const getStockStatusStyle = (stockCount) => {
    if (stockCount === 0) {
      return { color: 'red', fontWeight: 'bold' };
    } else if (stockCount <= lowStockThreshold) {
      return { color: 'orange', fontWeight: 'bold' };
    }
    return { color: 'green', fontWeight: 'bold' };
  };

  return (
    <>
      <AddMedicine
        show={showAddMedicineModal}
        onHide={() => setShowAddMedicineModal(false)}
        refetch={fetchInventory}
      />
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="form-head dashboard-head d-flex mb-4 align-items-center justify-content-between">
            <h2 className="text-black font-w600 mb-0">Inventory </h2>
            <div className="d-flex align-items-center gap-3">
              <select 
                className="form-control"
                value={stockFilter}
                onChange={(e) => setStockFilter(e.target.value)}
              >
                <option value="all">All Items</option>
                <option value="in-stock">In Stock</option>
                <option value="low-stock">Low Stock</option>
                <option value="out-of-stock">Out of Stock</option>
              </select>
              <div className="input-group search-area">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  onChange={(e) => setSearchString(e.target.value)}
                />
                <span className="input-group-text rounded-0 pointer">
                  <Link to={"#"}>
                    <i className="flaticon-381-search-2"></i>
                  </Link>
                </span>
              </div>
              <button
                className="btn btn-secondary"
                onClick={() => {
                  setShowAddMedicineModal(true);
                  dispatch(setTransferInvertoryActionNull());
                }}
              >
                Add
              </button>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-xl-3 col-sm-6">
              <div className="card">
                <div className="card-body">
                  <h4>Total Items</h4>
                  <h3>{inventoryData.length}</h3>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6">
              <div className="card">
                <div className="card-body">
                  <h4>In Stock</h4>
                  <h3>{inventoryData.filter(item => item.stockCount > lowStockThreshold).length}</h3>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6">
              <div className="card">
                <div className="card-body">
                  <h4>Low Stock</h4>
                  <h3>{inventoryData.filter(item => item.stockCount <= lowStockThreshold && item.stockCount > 0).length}</h3>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6">
              <div className="card">
                <div className="card-body">
                  <h4>Out of Stock</h4>
                  <h3>{inventoryData.filter(item => item.stockCount === 0).length}</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="table-responsive">
                <div
                  id="example5_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <Card>
                    <Card.Header>
                      {/* <Card.Title>Inventory</Card.Title> */}
                    </Card.Header>
                    <Card.Body>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>
                              <strong>Sr. No</strong>
                            </th>
                            <th>
                              <strong>Medicine Name</strong>
                            </th>
                            <th>
                              <strong>Price Per Unit($)</strong>
                            </th>
                            <th>
                              <strong>Stock Count</strong>
                            </th>
                            <th>
                              <strong>Updated On</strong>
                            </th>
                            <th>
                              <strong>Action</strong>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {!loading &&
                            getFilteredInventory().map((data, ind) => (
                              <tr key={ind}>
                                <td>
                                  <strong>{ind + 1}</strong>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <span className="w-space-no">
                                      {data.medicineName}
                                    </span>
                                  </div>
                                </td>
                                <td>{data.costPerUnit}</td>
                                <td>
                                  <span style={getStockStatusStyle(data.stockCount)}>
                                    {data.stockCount}
                                    {data.stockCount === 0 && " (Out of Stock)"}
                                    {data.stockCount <= lowStockThreshold && data.stockCount > 0 && " (Low Stock)"}
                                  </span>
                                </td>
                                <td>
                                  {moment(data.updatedAt).format("DD-MM-YYYY")}
                                </td>
                                <td>
                                  <div className="action-buttons d-flex align-items-center justify-content-start">
                                    <button
                                      className="btn btn-primary mr-2"
                                      onClick={() => editHandler(data)}
                                    >
                                      <FaRegEdit />
                                    </button>
                                    {id === data._id &&
                                    deleteLoading === true ? (
                                      <Spinner
                                        animation="border"
                                        variant="info"
                                      />
                                    ) : (
                                      <button
                                        className="btn btn-danger light"
                                        onClick={() => {
                                          deleteHandler(data._id);
                                        }}
                                      >
                                        <MdDeleteOutline />
                                      </button>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                        {loading && (
                          <tbody>
                            <td>
                              <Skeleton count={6} height={50} />
                            </td>
                            <td>
                              <Skeleton count={6} height={50} />
                            </td>
                            <td>
                              <Skeleton count={6} height={50} />
                            </td>
                            <td>
                              <Skeleton count={6} height={50} />
                            </td>
                            <td>
                              <Skeleton count={6} height={50} />
                            </td>
                            <td>
                              <Skeleton count={6} height={50} />
                            </td>
                            <td>
                              <Skeleton count={6} height={50} />
                            </td>
                          </tbody>
                        )}
                      </Table>
                      {inventoryData.length == 0 && !loading && <div className="d-flex justify-content-center align-items-center" style={{height:"200px"}}>
                        <div style={{fontSize:"30px"}}>
                          No Data
                        </div>
                      </div>}
                      <div className="d-flex justify-content-between mt-3">
                        <span>
                          Page{" "}
                          <strong>
                            {pageCount} of {Math.ceil(total/10)}
                          </strong>
                          {""}
                        </span>
                        <span className="table-index">
                          Go to page :{" "}
                          <input
                            type="number"
                            className="ml-2"
                            defaultValue={pageCount}
                            onChange={(e) => {
                              const pageNumber = e.target.value
                                ? Number(e.target.value)
                                : 1;
                              gotoPage(pageNumber);
                            }}
                          />
                        </span>
                      </div>
                      <div className="text-center">
                        <div className="filter-pagination  mt-3">
                          <button
                            className=" previous-button"
                            onClick={() => gotoPage(1)}
                            disabled={pageCount <= 1}
                          >
                            {"<<"}
                          </button>

                          <button
                            className="previous-button"
                            onClick={() => previousPage()}
                            disabled={pageCount <= 1}
                          >
                            Previous
                          </button>
                          <button
                            className="next-button"
                            onClick={() => nextPage()}
                            disabled={Math.ceil(total/10) <= pageCount}
                          >
                            Next
                          </button>
                          <button
                            className=" next-button"
                            onClick={() => gotoPage(Math.ceil(total/10))}
                            disabled={Math.ceil(total/10) <= pageCount}
                          >
                            {">>"}
                          </button>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>

                  <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                    {/* pagingation */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InventoryManagement;
