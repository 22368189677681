import {
  ADD_UPLOAD_REPORT,
  CREATE_INVOICE,
  CREATE_LAB_INVOICE,
  EDIT_LAB_PROFILE,
  EDIT_PHARMA_PROFILE,
  GET_LAB_ORDER_LISTS,
  GET_LAB_PROFILE_DATA,
  GET_NOTIFICATION_API,
  GET_ORDER_DETAILS,
  GET_ORDER_LAB_DETAILS,
  GET_ORDER_LISTS,
  GET_PHARMA_PROFILE_DATA,
} from "./AuthApiEndPoints";
import axiosInstance from "./Instance";
import {
  CHANGE_LAB_STATUS,
  CHANGE_STATUS,
  CREATE_COUNT_DASHBOARD_LAB,
  CREATE_COUNT_DASHBOARD_PHARMA,
  CREATE_INVOICE_LAB,
  CREATE_INVOICE_PHARMA,
  DELETE_MEDICINE,
  GET_LAB_TEST_SUGGENTION,
  GET_MEDICINE_LIST,
  POST_MEDICINE,
  PUT_MEDICINE,
} from "./User/UserApiEndPoints";

export const getOrders = async (type, page, method) => {
  return axiosInstance.get(
    GET_ORDER_LISTS + `?page=${page}&limit=10&type=${type}&method=${method}`
  );
};

export const getLabOrders = async (type, page,method) => {
  return axiosInstance.get(
    GET_LAB_ORDER_LISTS + `?page=${page}&limit=10&type=${type}&method=${method}`
  );
};

export const editLabProfile = async (payload) => {
  return axiosInstance.put(EDIT_LAB_PROFILE, payload);
};

export const editPharmaProfile = async (payload) => {
  return axiosInstance.put(EDIT_PHARMA_PROFILE, payload);
};

export const getLabProfile = async (payload) => {
  return axiosInstance.get(GET_LAB_PROFILE_DATA, payload);
};

export const getPharmaProfile = async (payload) => {
  return axiosInstance.get(GET_PHARMA_PROFILE_DATA, payload);
};

export const getOrderDetails = async (id) => {
  return axiosInstance.get(GET_ORDER_DETAILS + `?orderId=${id}`);
};

export const postInvoiceToPatient = async (payload) => {
  return axiosInstance.put(CREATE_INVOICE, payload);
};

export const uploadReportData = async (payload) => {
  return axiosInstance.put(ADD_UPLOAD_REPORT, payload);
};

export const postMedicine = async (payload) => {
  return axiosInstance.post(POST_MEDICINE, payload);
};

export const putMedicine = async (payload) => {
  return axiosInstance.put(PUT_MEDICINE, payload);
};

export const deleteMedicine = async (id) => {
  return axiosInstance.delete(DELETE_MEDICINE + id);
};

export const getInventory = async (search, page) => {
  return axiosInstance.get(
    GET_MEDICINE_LIST + `?page=${page}&limit=10&search=${search}`
  );
};

export const getInventoryModal = async (search, page) => {
  return axiosInstance.get(
    GET_MEDICINE_LIST + `?page=${page}&limit=100&search=${search}`
  );
};

export const getLabTestSuggention = async (search) => {
  return axiosInstance.get(
    GET_LAB_TEST_SUGGENTION + `?search=${search}`
  );
};

export const changeOrderStatus = async (payload) => {
  return axiosInstance.put(CHANGE_STATUS, payload);
};

export const getOrderLabDetails = async (id) => {
  return axiosInstance.get(GET_ORDER_LAB_DETAILS + `?orderId=${id}`);
};

export const postLabInvoiceToPatient = async (payload) => {
  return axiosInstance.put(CREATE_LAB_INVOICE, payload);
};

export const changeLabOrderStatus = async (payload) => {
  return axiosInstance.put(CHANGE_LAB_STATUS, payload);
};

export const createInvoiceLab = async (payload) => {
  return axiosInstance.post(CREATE_INVOICE_LAB, payload);
};

export const createInvoicePharma = async (payload) => {
  return axiosInstance.post(CREATE_INVOICE_PHARMA, payload);
};

export async function dashBoardDataPharma() {
  return axiosInstance.get(CREATE_COUNT_DASHBOARD_PHARMA);
}

export async function dashBoardDataLab() {
  return axiosInstance.get(CREATE_COUNT_DASHBOARD_LAB);
}

export async function getNotificationToken(data) {
  try {
    const response = await axiosInstance.put(GET_NOTIFICATION_API, data);
    return response;
  } catch (error) {
    throw error; 
  }
}
