import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

/// Image
import profile from "../../../images/user-icon3.png";
import { Dropdown } from "react-bootstrap";
import LogoutPage from "./Logout";
import ChangePassword from "../../modal/ChangePassword";
import { useDispatch, useSelector } from "react-redux";
import { profileUpdateAction } from "../../../store/actions/AuthActions";

const Header = ({ onNote }) => {
  const dispatch = useDispatch();
  const loginType = localStorage.getItem("anyTimeHospitalType");
  const [changePassword, setChangePassword] = useState(false);
  const [userDetails, setUserDetails] = useState(
    localStorage.getItem("anyTimeHospitalName")
  );
  const profileUpdate = useSelector((state) => state.auth.profileUpdate);

  useEffect(() => {
    if (profileUpdate) {
      setUserDetails(localStorage.getItem("anyTimeHospitalName"));
      dispatch(profileUpdateAction(false));
    }
  }, [profileUpdate]);

  return (
    <>
      <ChangePassword
        password={changePassword}
        onHide={() => setChangePassword(false)}
      />
      <div className="header" style={{ zIndex: "11" }}>
        <div className="header-content">
          <nav className="navbar navbar-expand">
            <div className="collapse navbar-collapse justify-content-between">
              <div className="header-left"></div>
              <ul className="navbar-nav header-right">
                <Dropdown as="li" className="nav-item dropdown header-profile">
                  <Dropdown.Toggle
                    variant=""
                    as="a"
                    className="i-false c-pointer nav-link"
                    to=""
                    role="button"
                    data-toggle="dropdown"
                  >
                    <div className="header-info">
                      <span>{userDetails}</span>
                    </div>
                    <img src={profile} width={20} alt="" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    align="right"
                    className="dropdown-menu dropdown-menu-right"
                  >
                    {loginType == "Pharmacy" && (
                      <Link
                        to="/app-profile-pharma"
                        className="dropdown-item ai-icon"
                      >
                        <svg
                          id="icon-user1"
                          xmlns="http://www.w3.org/2000/svg"
                          className="text-primary"
                          width={18}
                          height={18}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                          <circle cx={12} cy={7} r={4} />
                        </svg>
                        <span className="ml-2">Profile</span>
                      </Link>
                    )}
                    {loginType == "Lab" && (
                      <Link
                        to="/app-profile-lab"
                        className="dropdown-item ai-icon"
                      >
                        <svg
                          id="icon-user1"
                          xmlns="http://www.w3.org/2000/svg"
                          className="text-primary"
                          width={18}
                          height={18}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                          <circle cx={12} cy={7} r={4} />
                        </svg>
                        <span className="ml-2">Profile </span>
                      </Link>
                    )}
                    <Link
                      className="dropdown-item ai-icon"
                      onClick={() => setChangePassword(true)}
                      style={{ backgroundColor: "#fff" }}
                    >
                      <svg
                        fill="#004000"
                        height="20px"
                        width="20px"
                        version="1.1"
                        id="Icon"
                        xmlns="http://www.w3.org/2000/svg"
                        xlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 24 24"
                        enable-background="new 0 0 24 24"
                        space="preserve"
                      >
                        <path
                          d="M24,19v-2h-2.14c-0.09-0.36-0.24-0.7-0.42-1.02l1.52-1.52l-1.41-1.41l-1.52,1.52c-0.32-0.19-0.66-0.33-1.02-0.42V12h-2v2.14
                   c-0.36,0.09-0.7,0.24-1.02,0.42l-1.52-1.52l-1.41,1.41l1.52,1.52c-0.19,0.32-0.33,0.66-0.42,1.02H12v2h2.14
                   c0.09,0.36,0.24,0.7,0.42,1.02l-1.52,1.52l1.41,1.41l1.52-1.52c0.32,0.19,0.66,0.33,1.02,0.42V24h2v-2.14
                   c0.36-0.09,0.7-0.24,1.02-0.42l1.52,1.52l1.41-1.41l-1.52-1.52c0.19-0.32,0.33-0.66,0.42-1.02H24z M18,20c-1.1,0-2-0.9-2-2
                   s0.9-2,2-2s2,0.9,2,2S19.1,20,18,20z M11,7.41l3.29,3.29l1.41-1.41L12.41,6L13,5.41l2.29,2.29l1.41-1.41L14.41,4L15,3.41l3.29,3.29
                   l1.41-1.41L16.41,2l0.29-0.29l-1.41-1.41L6.89,8.7C6.19,8.26,5.38,8,4.5,8C2.02,8,0,10.02,0,12.5S2.02,17,4.5,17S9,14.98,9,12.5
                   c0-0.88-0.26-1.69-0.7-2.39L11,7.41z M4.5,15C3.12,15,2,13.88,2,12.5S3.12,10,4.5,10S7,11.12,7,12.5S5.88,15,4.5,15z"
                        />
                      </svg>
                      <span className="ml-2">Change Password </span>
                    </Link>
                    <LogoutPage />
                  </Dropdown.Menu>
                </Dropdown>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Header;
