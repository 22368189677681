import React, { useEffect, useState } from "react";
import {
  labOrderStatusKeyValue,
  orderStatus,
  orderStatusKeyValue,
} from "../../utils/content";

const LabOrderStatusStepper = ({ currentStatusArray }) => {
  console.log(currentStatusArray, "IUUIIUUIIUIU");

  const steps = [
    "received",
    "sampleCollected",
    "ReportGenerated",
    "reportDelivered",
  ];

  const [completedSteps, setCompletedSteps] = useState([...currentStatusArray]);

  useEffect(() => {
    setCompletedSteps([...currentStatusArray]);
  }, [currentStatusArray]);
  return (
    <div className="order-status-stepper">
      {steps.map((step, index) => (
        <div key={step} className="step">
          <div
            className={`step-circle ${
              completedSteps.includes(step) ? "completed" : ""
            }`}
          >
            {completedSteps.includes(step) ? (
              <svg
                className="checkmark"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
            ) : (
              <span className="step-number">{index + 1}</span>
            )}
          </div>
          <div
            className={`step-label ${
              completedSteps.includes(step) ? "completed" : ""
            }`}
          >
            {labOrderStatusKeyValue[step]}
          </div>
        </div>
      ))}
    </div>
  );
};

export default LabOrderStatusStepper;
