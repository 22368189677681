import { Icon } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Card, Col, Modal, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import { HiOutlineTrash } from "react-icons/hi";
import {
  postInvoiceToPatient,
  postLabInvoiceToPatient,
} from "../../../services/AsyncFunctions";

function CreateLabInvoice({ onHide, show, orderId, fetchOrderDetails, list }) {
  const [loading, setLoading] = useState(false);
  const [medicineName, setMedicineName] = useState("");
  const [totalCost, setTotalCost] = useState("");
  const [invoiceMedicineList, setInvoiceMedicine] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);

  async function submit() {
    if (invoiceMedicineList?.length > 0) {
      const result = await Swal.fire({
        title: "Are you sure you want to send this invoice to the patient?",
        text: "This action cannot be undone!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });

      if (!result.isConfirmed) return;
      let payload = {
        orderId,
        status: "Accept",
        invoice: [...invoiceMedicineList],
        totalPrice: grandTotal,
      };
      setLoading(true);
      try {
        const response = await postLabInvoiceToPatient(payload);
        console.log(response);
        fetchOrderDetails();
        onHide();
      } catch (error) {
        toast.error(error?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    }
  }

  function addMedicineToInvoiceList(e) {
    e.preventDefault();
    if (!medicineName) {
      toast.error("Enter medicine name");
      return;
    } else if (
      !totalCost ||
      totalCost <= 0 ||
      !Number.isInteger(Number(totalCost))
    ) {
      toast.error("Enter Total Cost");
      return;
    } else {
      if (invoiceMedicineList.length == 0) {
        setInvoiceMedicine([
          {
            testName: medicineName,
            // quantity,
            price: totalCost,
          },
        ]);
      } else {
        setInvoiceMedicine((prev) => [
          ...prev,
          {
            testName: medicineName,
            // quantity,
            price: totalCost,
          },
        ]);
      }
      setMedicineName("");
      //   setQuantity("");
      setTotalCost(1);
    }
  }
  function removeMedicineFromList(ItemIndex) {
    const tempList = invoiceMedicineList.filter(
      (item, index) => ItemIndex !== index
    );
    setInvoiceMedicine([...tempList]);
  }

  useEffect(() => {
    if (invoiceMedicineList.length > 0) {
      const totalAmount = invoiceMedicineList.reduce(
        (total, item) => total + Number(item.price),
        0
      );
      setGrandTotal(totalAmount);
    }
  }, [invoiceMedicineList]);

  return (
    <Modal
      className="modal fade"
      size="lg"
      centered
      onHide={onHide}
      show={show}
    >
      <Col>
        <Card>
          <Card.Header className="d-block">
            <div className="d-flex justify-content-between">
              <h3>Create Invoice</h3>
              <div>
                {list &&
                  list.map((item, index) => (
                    <div
                      key={item._id}
                      className="d-flex align-items-start flex-column mt-3"
                    >
                      <div className="pharma-card_distance mb-2">
                        <span className="font-weight-bold">{index + 1}.</span>
                        {item.name}
                      </div>
                    </div>
                  ))}
              </div>

              {/* <button type="button" className="btn-close"  data-dismiss="modal">X</button> */}
            </div>
          </Card.Header>

          <Card.Body>
            <form
              onSubmit={addMedicineToInvoiceList}
              className="d-flex align-items-center"
            >
              <input
                type="text"
                placeholder="Test Name"
                value={medicineName}
                onChange={(e) => setMedicineName(e.target.value)}
                className="form-control mr-2"
                name="medicine"
              />
              {/* <input
                  type="text"
                  placeholder="Quantity"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  className="form-control mr-2"
                  name="quantity"
                /> */}
              <input
                type="text"
                placeholder="Total Price"
                value={totalCost}
                onChange={(e) => setTotalCost(e.target.value)}
                className="form-control mr-2"
                name="price"
              />
              <button className="btn btn-outline-primary" type="submit">
                Add
              </button>
            </form>
            {invoiceMedicineList.length > 0 && (
              <ol className="">
                <div className="row mt-3">
                  <div className="col-4  pharma-card_distance invoice-table-header">
                    Test Name
                  </div>
                  {/* <div className="col-4 pharma-card_distance invoice-table-header">
                     Quantity
                    </div> */}
                  <div className="col-4 pharma-card_distance invoice-table-header">
                    Total Price
                  </div>
                </div>
                {invoiceMedicineList.map((item, index) => (
                  <div key={index} className="row mt-3 ">
                    <div className="col-4 pharma-card_distance">
                      <span className="font-weight-bold">{index + 1}.</span>{" "}
                      {item.testName}
                    </div>
                    {/* <div className="col-4 pharma-card_distance">
                      {item.quantity}
                    </div> */}
                    <div className=" col-2 pharma-card_distance d-flex align-items-center">
                      <span className="">{item.price}</span>
                    </div>
                    <div
                      className=" col-2 pharma-card_distance d-flex align-items-end justify-content-end"
                      onClick={() => removeMedicineFromList(index)}
                    >
                      <HiOutlineTrash
                        size={"25px"}
                        color="red"
                        className="ml-2 pointer"
                      />
                    </div>
                  </div>
                ))}
                <div className="d-flex pharma-card_distance align-items-center justify-content-end mt-5">
                  <div>ORDER TOTAL: ${grandTotal}</div>
                </div>
              </ol>
            )}
          </Card.Body>
          <Card.Footer>
            <button
              className="btn btn-primary w-100"
              onClick={submit}
              disabled={loading}
            >
              {loading ? <Spinner animation="border" /> : "Send Invoice"}
            </button>
          </Card.Footer>
        </Card>
      </Col>
    </Modal>
  );
}

export default CreateLabInvoice;
