export const SOCKET_GLOBAL_EVENTS = {
  RECIEVE_REQUEST: "receiveRequest",
  NO_RESPONSE_FROM_DOCTOR: "noResponseFromDoctor",
  JOIN_CALL_AFTER_PAYMENT: "joinCall",
  REQUEST_TIMED_OUT: "requestTimeOut",
};

export const APPOINTMENTS_EVENTS = {
  GET_UPCOMING_APPOINTMENTS: "getUpcomingAppointments",
  GET_APPOINTMENT_DETAILS: "getAppointmentDetails",
};

export const orderStatus = [
  { label: "Dispatched", value: "packed" },
  { label: "In Transit", value: "transit" },
  { label: "Delivered", value: "delivered" },
];

export const orderStatusKeyValue = {
  packed: "Dispatched",
  transit: "In Transit",
  delivered: "Delivered",
  received: "Order Received",
};

export const orderStatusKeyValueOffline = {
  packed: "Processing",
  transit: "Ready for Pickup",
  delivered: "Picked Up",
  received: "Order Received ",
};

export const labOrderStatusKeyValue = {
  sampleCollected: "Sample Collected",
  ReportGenerated: "Report Generated",
  reportDelivered: "Report Delivered",
  received: "Order Received",
};

const statusMapping = {
  isOrderReceived: "received",
  isOrderPacked: "packed",
  isTransit: "transit",
  isOrderDelivered: "delivered",
};

export const getStatusValues = (statusObject) => {
  console.log(statusObject, "lkklklklklk");
  // Filter keys with true values and map them to their corresponding values
  const trueKeys = Object.keys(statusObject).filter(
    (key) => statusObject[key] === true
  );
  console.log(trueKeys, "oooooooooooo");

  const statusValues = trueKeys.map((key) => statusMapping[key]);
  return statusValues;
};

const labStatusMapping = {
  isOrderReceived: "received",
  isSampleCollected: "sampleCollected",
  isReportDelivered: "reportDelivered",
  isReportGenerated: "ReportGenerated",
};
export const getStatuslabValues = (statusObject) => {
  console.log(statusObject, "lkklklklklk");
  // Filter keys with true values and map them to their corresponding values
  const trueKeys = Object.keys(statusObject).filter(
    (key) => statusObject[key] === true
  );
  console.log(trueKeys, "oooooooooooo");

  const statusValues = trueKeys.map((key) => labStatusMapping[key]);
  return statusValues;
};
