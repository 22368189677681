import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { postMedicine, putMedicine } from "../../services/AsyncFunctions";
import { useSelector } from "react-redux";

function AddMedicine({ show, onHide, refetch }) {
  const inventoryData = useSelector(
    (state) => state.inventory.inventoryData
  );

  console.log(inventoryData)
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    medicineName: "",
    costPerUnit: "",
    stockCount: ""
  })

  useEffect(() => {
    if (inventoryData !== null) {
      setPayload({
        medicineName: inventoryData.medicineName,
        costPerUnit: `${inventoryData.costPerUnit}`,
        stockCount: `${inventoryData.stockCount}`
      })
    } else {
      setPayload({
        medicineName: "",
        costPerUnit: "",
        stockCount: ""
      })
    }
  }, [inventoryData])

  async function addNewMedicine() {
    if (payload.medicineName.trim() === "") {
      toast.error("Medicine Name is required");
      return;
    }
    if (!payload.costPerUnit || payload.costPerUnit <= 0 || !Number.isInteger(Number(payload.costPerUnit))) {
      toast.error("Cost per unit is required");
      return;
    }
    if (!payload.stockCount || payload.stockCount <= 0 || !Number.isInteger(Number(payload.stockCount))) {
      toast.error("Stock count is required");
      return;
    }

    setLoading(true);
    try {
      if(inventoryData === null){
        const response = await postMedicine({...payload,medicineSalt:""});
      }else{
        const response = await putMedicine({...payload,medicineId:inventoryData._id,medicineSalt:""});
      }
      refetch();

      setPayload({
        medicineName: "",
        costPerUnit: "",
        stockCount: ""
      })
      onHide()

    } catch (error) {
      toast.error(error?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }

  function handleFormUpdate(e) {
    setPayload({
      ...payload,
      [e.target.name]: e.target.value
    })
  }
  return (
    <Modal className="fade" show={show} centered onHide={onHide}>
      <Modal.Header>
        <Modal.Title>{inventoryData !== null ? "Edit Medicine" : "Add Medicine"}</Modal.Title>
        <Button onClick={onHide} variant="" className="close">
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <input
          type="text"
          className="form-control input-default mb-2"
          placeholder="Medicine name"
          name="medicineName"
          value={payload.medicineName}
          onChange={(e) => handleFormUpdate(e)}
        />
        <input
          type="text"
          className="form-control input-default mb-2"
          placeholder="Cost per unit"
          value={payload.costPerUnit}
          name="costPerUnit"
          onChange={(e) => handleFormUpdate(e)}


        />
        <input
          type="text"
          className="form-control input-default mb-2"
          placeholder="Items in stock"
          value={payload.stockCount}
          name="stockCount"
          onChange={(e) => handleFormUpdate(e)}


        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="w-100" disabled={loading} onClick={addNewMedicine}>
          {loading ? <Spinner animation="border" /> : inventoryData !== null ? "Update To Inventory" : "Add To Inventory"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddMedicine;
