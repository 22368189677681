import { Icon } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Modal, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import { HiOutlineTrash } from "react-icons/hi";
import {
  getInventoryModal,
  postInvoiceToPatient,
} from "../../../services/AsyncFunctions";

function CreateInvoice({ onHide, show, orderId, fetchOrderDetails, list }) {
  const [loading, setLoading] = useState(false);
  const [medicineName, setMedicineName] = useState("");
  const [quantity, setQuantity] = useState("1");
  const [totalCost, setTotalCost] = useState("");
  const [priceItem, setPriceItem] = useState("");
  const [invoiceMedicineList, setInvoiceMedicine] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [inventoryData, setInvetoryData] = useState([]);
  const [medicineId, setmedicineId] = useState("");

  async function submit() {
    if (invoiceMedicineList?.length > 0) {
      const result = await Swal.fire({
        title: "Are you sure you want to send this invoice to the patient?",
        text: "This action cannot be undone!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });

      if (!result.isConfirmed) return;
      let payload = {
        orderId,
        status: "Accept",
        invoice: [...invoiceMedicineList],
        totalPrice: grandTotal,
      };
      setLoading(true);
      try {
        const response = await postInvoiceToPatient(payload);
        console.log(response);
        fetchOrderDetails();
        onHide();
      } catch (error) {
        toast.error(error?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    }
  }

  const handleSelectItem = (item) => {
    setSelectedItem(item);
    setMedicineName(item.medicineName);
    setTotalCost(item.costPerUnit);
    setPriceItem(item.costPerUnit);
    setmedicineId(item._id);
    setIsOpen(false);
  };

  const filteredItems = inventoryData.filter((item) =>
    item.medicineName.toLowerCase().includes(medicineName.toLowerCase())
  );

  function addMedicineToInvoiceList(e) {
    e.preventDefault();
    console.log(selectedItem);
    if (selectedItem === null) {
      toast.error("Select a medicine from the list");
      return;
    }else if (!medicineName) {
      toast.error("Medicine name is required");
      return;
    } else if (
      !quantity ||
      quantity <= 0 ||
      !Number.isInteger(Number(quantity))
    ) {
      toast.error("Quantity is required");
      return;
    } else if (!totalCost) {
      toast.error("Price is required");
      return;
    } else {
      if (invoiceMedicineList.length == 0) {
        setInvoiceMedicine([
          {
            medicineName: medicineName,
            quantity,
            price: totalCost,
            medicineId,
          },
        ]);
      } else {
        setInvoiceMedicine((prev) => [
          ...prev,
          {
            medicineName: medicineName,
            quantity,
            price: totalCost,
            medicineId,
          },
        ]);
      }
      setMedicineName("");
      setQuantity("");
      setTotalCost("");
      setSelectedItem(null);
    }
  }
  function removeMedicineFromList(ItemIndex) {
    const tempList = invoiceMedicineList.filter(
      (item, index) => ItemIndex !== index
    );
    setInvoiceMedicine([...tempList]);
  }

  useEffect(() => {
    if (invoiceMedicineList.length > 0) {
      const totalAmount = invoiceMedicineList.reduce(
        (total, item) => total + Number(item.price),
        0
      );
      setGrandTotal(totalAmount);
    }
  }, [invoiceMedicineList]);

  const handleInputChange = (e) => {
    setMedicineName(e.target.value || "");
    if (!isOpen) setIsOpen(true);
  };

  async function fetchInventory() {
    try {
      const result = medicineName.replace(/&/g, "");
      const response = await getInventoryModal(result, 0);
      console.log(response);
      setInvetoryData(response.data.data.medicines);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      console.log("finally");
    }
  }

  useEffect(() => {
    if (medicineName) {
      const delayDebounceFn = setTimeout(() => {
        fetchInventory();
      }, 100);
      return () => clearTimeout(delayDebounceFn);
    } else {
      fetchInventory();
    }
  }, [medicineName]);

  useEffect(() => {
    if (totalCost && medicineName && quantity) {
      setTotalCost(priceItem * quantity);
    } else if (!quantity) {
      setTotalCost(priceItem);
    }
  }, [quantity, medicineName]);

  return (
    <Modal
      className="modal fade"
      size="lg"
      centered
      onHide={onHide}
      show={show}
    >
      <Col>
        <Card>
          <Card.Header className="d-block">
            <div className="d-flex justify-content-between">
              <h3>Create Invoice</h3>
              <div>
                {list &&
                  list.map((item, index) => (
                    <div
                      key={item._id}
                      className="d-flex align-items-start flex-column"
                    >
                      <div className="pharma-card_distance">
                        <span className="font-weight-bold">{index + 1}.</span>{" "}
                        {item.name}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </Card.Header>

          <Card.Body>
            <form
              onSubmit={addMedicineToInvoiceList}
              className="d-flex align-items-center"
            >
              <div
                ref={dropdownRef}
                className="relative mt-10"
                style={{ width: "100%" }}
              >
                <input
                  type="text"
                  value={medicineName}
                  onChange={handleInputChange}
                  onClick={() => setIsOpen(true)}
                  placeholder="Select Medicine"
                  className="form-control mr-2"
                  style={{ width: "90%" }}
                />
                {selectedItem && (
                  <button
                    type="button"
                    className="btn btn-link position-absolute"
                    style={{
                      right: "25px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      padding: "0",
                      border: "none",
                      background: "none",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedItem(null);
                      setMedicineName("");
                      setQuantity("");
                      setTotalCost("");
                    }}
                  >
                    <span style={{ fontSize: "20px" }}>&times;</span>
                  </button>
                )}
                {isOpen && (
                  <ul
                    className="absolute z-100 w-full bg-white border rounded-lg shadow-lg"
                    style={{
                      position: "absolute",
                      zIndex: 9999,
                      maxHeight: "280px",
                      overflowY: "auto",
                      scrollbarWidth: "thin",
                      scrollbarColor: "#888 #f1f1f1",
                      marginTop: "4px",
                    }}
                  >
                    <style>
                      {`
                        ul::-webkit-scrollbar {
                          width: 6px;
                        }
                        ul::-webkit-scrollbar-track {
                          background: #f1f1f1;
                          border-radius: 3px;
                        }
                        ul::-webkit-scrollbar-thumb {
                          background: #888;
                          border-radius: 3px;
                        }
                        ul::-webkit-scrollbar-thumb:hover {
                          background: #555;
                        }
                        .dropdown-item:hover {
                          background-color: #f8f9fa;
                          transition: all 0.2s ease;
                        }
                      `}
                    </style>
                    {inventoryData.length > 0 ? (
                      filteredItems.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => handleSelectItem(item)}
                          className="dropdown-item px-4 border-b last:border-b-0"
                          style={{
                            padding: "12px 16px",
                            cursor: "pointer",
                          }}
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <span
                                className="font-weight-medium"
                                style={{ color: "#2c3e50" }}
                              >
                                {item.medicineName}
                              </span>
                              <div className="text-muted small">
                                ID: {item._id.slice(-6)}
                              </div>
                            </div>
                            <div
                              className="text-primary font-weight-bold"
                              style={{
                                backgroundColor: "#e8f4ff",
                                padding: "4px 12px",
                                borderRadius: "16px",
                                fontSize: "14px",
                              }}
                            >
                              ${item.costPerUnit}
                            </div>
                          </div>
                        </li>
                      ))
                    ) : (
                      <li className="px-4 py-3 text-muted text-center">
                        No results found
                      </li>
                    )}
                  </ul>
                )}
              </div>
              <input
                type="text"
                placeholder="Quantity"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                className="form-control mr-2"
                name="quantity"
              />
              <input
                type="text"
                placeholder="Total Price"
                value={totalCost}
                onChange={(e) => setTotalCost(e.target.value)}
                className="form-control mr-2"
                name="price"
              />
              <button className="btn btn-outline-primary" type="submit">
                Add
              </button>
            </form>
            {invoiceMedicineList.length > 0 && (
              <ol className="">
                <div className="row mt-3">
                  <div className="col-4  pharma-card_distance invoice-table-header">
                    Medicine Name
                  </div>
                  <div className="col-4 pharma-card_distance invoice-table-header">
                    Quantity
                  </div>
                  <div className="col-4 pharma-card_distance invoice-table-header">
                    Total Price
                  </div>
                </div>
                {invoiceMedicineList.map((item, index) => (
                  <div key={index} className="row mt-3">
                    <div className="col-4 pharma-card_distance">
                      <span className="font-weight-bold">{index + 1}.</span>{" "}
                      {item.medicineName}
                    </div>
                    <div className="col-4 pharma-card_distance">
                      {item.quantity}
                    </div>
                    <div className=" col-2 pharma-card_distance d-flex align-items-center">
                      <span className="">{item.price}</span>
                    </div>
                    <div
                      className=" col-2 pharma-card_distance d-flex align-items-center"
                      onClick={() => removeMedicineFromList(index)}
                    >
                      <HiOutlineTrash
                        size={"25px"}
                        color="red"
                        className="ml-2 pointer"
                      />
                    </div>
                  </div>
                ))}
                <div className="d-flex pharma-card_distance align-items-center justify-content-end mt-5">
                  <div>ORDER TOTAL: ${grandTotal}</div>
                </div>
              </ol>
            )}
          </Card.Body>
          <Card.Footer>
            <button
              className="btn btn-primary w-100"
              onClick={submit}
              disabled={loading}
            >
              {loading ? <Spinner animation="border" /> : "Send Invoice"}
            </button>
          </Card.Footer>
        </Card>
      </Col>
    </Modal>
  );
}

export default CreateInvoice;
