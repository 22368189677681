import React, { useEffect, useState } from "react";
import {
  orderStatus,
  orderStatusKeyValue,
  orderStatusKeyValueOffline,
} from "../../utils/content";

const OrderStatusStepper = ({ currentStatusArray, method }) => {
  const steps = ["received", "packed", "transit", "delivered"];
  const [completedSteps, setCompletedSteps] = useState([...currentStatusArray]);

  // Determine which steps are completed based on the current status
  // let completedSteps = steps.slice(0, steps.indexOf(currentStatus) + 1);

  useEffect(() => {
    setCompletedSteps([...currentStatusArray]);
  }, [currentStatusArray]);
  return (
    <div className="order-status-stepper">
      {/* Render each step */}
      {steps.map((step, index) => (
        <div key={step} className="step">
          {/* Step circle */}
          <div
            className={`step-circle ${
              completedSteps.includes(step) ? "completed" : ""
            }`}
          >
            {/* Step number */}
            {completedSteps.includes(step) ? (
              <svg
                className="checkmark"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
            ) : (
              <span className="step-number">{index + 1}</span>
            )}
          </div>
          {/* Step label */}
          <div
            className={`step-label ${
              completedSteps.includes(step) ? "completed" : ""
            }`}
          >
            {method === "online"
              ? orderStatusKeyValue[step]
              : orderStatusKeyValueOffline[step]}
          </div>
        </div>
      ))}
    </div>
  );
};

export default OrderStatusStepper;
