import React, { useEffect, useState } from "react";
import { Card, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import moment from "moment";
import { APPOINTMENTS_EVENTS, getStatusValues } from "../../utils/content";
import eventBus from "../../services/Eventbus";
import {
  changeOrderStatus,
  getOrderDetails,
  postInvoiceToPatient,
} from "../../services/AsyncFunctions";
import OrderStatusStepper from "../components/OrderStatusStepper";
// import SelectPharmacy from "../modal/SelectPharmacy";
import Select from "react-select";
import CreateInvoice from "../components/modals/CreateInvoice";
import Swal from "sweetalert2";

function OrderDetails() {
  const { orderId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const [showPharmacyModal, setShowPharmacyModal] = useState(false);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [loading, setLoading] = useState(false);

  //  const orderStatus = [
  //   {label: "Dispatched", value: "packed"},
  //   {label: "In Transit", value: "transit"},
  //   {label: "Delivered", value: "delivered"},

  // ]
  const orderStatus = [
    {
      label: "Received",
      value: "Receive",
      condition: orderDetails?.orderStatus.isOrderReceived,
    },

    {
      label: "Dispatched",
      value: "packed",
      condition: orderDetails?.orderStatus.isOrderPacked,
    },
    {
      label: "In Transit",
      value: "transit",
      condition: orderDetails?.orderStatus.isTransit,
    },
    {
      label: "Delivered",
      value: "delivered",
      condition: orderDetails?.orderStatus.isOrderDelivered,
    },
  ];

  const orderStatusOffline = [
    {
      label: "Order Received",
      value: "Receive",
      condition: orderDetails?.orderStatus.isOrderReceived,
    },

    {
      label: "Processing",
      value: "packed",
      condition: orderDetails?.orderStatus.isOrderPacked,
    },
    {
      label: "Ready for Pickup",
      value: "transit",
      condition: orderDetails?.orderStatus.isTransit,
    },
    {
      label: "Picked Up",
      value: "delivered",
      condition: orderDetails?.orderStatus.isOrderDelivered,
    },
  ];

  // Filter options where the condition is false
  const filteredOrderStatus =
    orderDetails?.method === "online"
      ? orderStatus.filter((status) => !status.condition)
      : orderStatusOffline.filter((status) => !status.condition);

  async function fetchOrderDetails() {
    if (!orderId) {
      toast.error("Could not fetch details");
      return;
    }
    setIsLoading(true);
    try {
      let response = await getOrderDetails(orderId);
      console.log(response, "pharma order details");
      setOrderDetails(response?.data?.data);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function reject() {
    // if (invoiceMedicineList?.length > 0) {
    const result = await Swal.fire({
      title: "Are you sure you want to reject this invoice .",
      text: "This action cannot be undone!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });

    if (!result.isConfirmed) return;
    let payload = {
      orderId: orderId,
      status: "Reject",
    };
    setLoading(true);
    try {
      const response = await postInvoiceToPatient(payload);
      console.log(response);
      fetchOrderDetails();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
    // }
  }

  // async function changeStatus() {
  //   // if (invoiceMedicineList?.length > 0) {

  //   let payload = {
  //     orderId: orderId,
  //     status: "Reject",
  //   };
  //   // setLoading(true);
  //   try {
  //     const response = await postInvoiceToPatient(payload);
  //     console.log(response);
  //   } catch (error) {
  //     toast.error(error?.response?.data?.message);
  //   } finally {
  //     // setLoading(false);
  //   }
  //   // }
  // }

  async function submitPrescription(prescriptionList, pharmaIdList) {
    setIsLoading(true);
    const payload = {
      // appointmentId: appointmentId.id,
      // pharmacies: [
      //   ...pharmaIdList
      // ],
      // prescriptions: [
      //   ...prescriptionList
      // ]
    };
    try {
      // const result = await sendPrescriptionToUser(payload);
      // if(result.status == 200 ){
      //   toast.success("Prescription sent to user");
      //   setShowPharmacyModal(false);
      //   getAppointmentDetails();
      // }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function updateOrderStatus(status) {
    try {
      const response = await changeOrderStatus({
        orderId,
        status,
      });
      fetchOrderDetails();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }

  function handleStatusChange(status) {
    updateOrderStatus(status.value);
  }

  useEffect(() => {
    fetchOrderDetails();
    // getAppointmentDetails();
    // let eventId = eventBus.on(APPOINTMENTS_EVENTS.GET_APPOINTMENT_DETAILS, getAppointmentDetails);
    // return () => {
    //   eventBus.removeListener(eventId);
    // };
  }, []);

  console.log(orderDetails, "sjsdhfjhskjfh");

  return (
    <>
      <CreateInvoice
        show={showInvoiceModal}
        onHide={() => setShowInvoiceModal(false)}
        orderId={orderId}
        fetchOrderDetails={() => fetchOrderDetails()}
        list={orderDetails?.prescriptions}
      />
      <div className="form-head d-flex mb-3 align-items-start">
        <div className="mr-auto w-100 d-flex align-items-center justify-content-between flex-wrap">
          <h2 className="text-black font-w600 mb-0">Order Details</h2>
          <div className="col-auto d-flex align-items-start flex-wrap mt-4">
            {(orderDetails?.status === "Pending" ||
              orderDetails?.status === "Awaiting") &&
              !orderDetails.isAccept &&
              orderDetails.mayPharmacy.status === "Pending" && (
                <div className="col-auto d-flex align-items-center">
                  <button
                    className="btn btn-success p-2 px-3 fs-12 mr-2"
                    onClick={() => setShowInvoiceModal(true)}
                  >
                    Accept
                  </button>
                  <button
                    className="btn bg-danger text-white p-2 px-3 fs-12"
                    onClick={reject}
                    disabled={loading}
                  >
                    Reject
                  </button>
                </div>
              )}
          </div>
        </div>
      </div>
      {!isLoading && orderDetails && (
        <div className="row">
          {orderDetails?.type !== "offline" && (
            <Card className="col-11 col-md-5 p-3 flex-row flex-grow-1 mx-auto mb-3">
              <div className="d-flex align-items-start">
              {orderDetails?.bookingFor === "mySelf" && <img  
                  src={
                    orderDetails?.user?.image
                      ? process.env.REACT_APP_BUCKET_BASE_URL +
                        orderDetails?.user?.image
                      : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                  }
                  alt=""
                  className="appointment-details-img"
                />}
                 {orderDetails?.bookingFor === "familyMember" && <img  
                  src={
                    orderDetails?.user?.image
                      ? process.env.REACT_APP_BUCKET_BASE_URL +
                        orderDetails?.member?.image
                      : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                  }
                  alt=""
                  className="appointment-details-img"
                />}
              </div>
              <div className="row w-100 ml-2">
                <div className="col-6">
                  <div className="">
                    <label htmlFor="" className="fs-12 mb-0">
                      Patient Name
                    </label>
                    <div className="fs-14 font-weight-bold capitalize">
                      {orderDetails?.bookingFor === "familyMember"
                        ? orderDetails?.member?.name
                        : orderDetails?.user?.name}
                    </div>
                  </div>
                  <div className="">
                    <label htmlFor="" className="fs-12 mb-0">
                      Email
                    </label>
                    <div className="fs-14 font-weight-bold capitalize">
                      {orderDetails?.bookingFor === "familyMember"
                        ? orderDetails?.member?.memberEmail
                        : orderDetails?.user?.email}
                    </div>
                  </div>
                  <div className="">
                    <label htmlFor="" className="fs-12 mb-0">
                      Doctor
                    </label>
                    <div className="fs-14 font-weight-bold capitalize">
                      {orderDetails?.doctor?.name}
                    </div>
                  </div>
                  <div className="">
                    <label htmlFor="" className="fs-12 mb-0">
                      Age
                    </label>
                    <div className="fs-14 font-weight-bold capitalize">
                      {orderDetails?.bookingFor === "familyMember"
                        ? moment().diff(
                            moment(
                              orderDetails?.member?.dateOfBirth,
                              "DD-MM-YYYY"
                            ),
                            "years"
                          )
                        : moment().diff(
                            moment(
                              orderDetails?.user?.dateOfBirth,
                              "DD-MM-YYYY"
                            ),
                            "years"
                          )}{" "}
                      Years
                    </div>
                  </div>
                </div>
                <div className="col-6">
                <div className="">
                  <label htmlFor="" className="fs-12 mb-0">
                    Booking For
                  </label>
                  <div className="fs-14 font-weight-bold capitalize">
                    {orderDetails?.bookingFor == "mySelf"
                      ? "Self"
                      : "Member"}
                  </div>
                </div>
                  <div className="">
                    <label htmlFor="" className="fs-12 mb-0">
                      Order Type
                    </label>
                    <div className="fs-14 font-weight-bold capitalize">
                      {orderDetails?.method == "online" ? "Delivery" : "Pickup"}
                    </div>
                  </div>
                  <div className="">
                    <label htmlFor="" className="fs-12 mb-0">
                      Order Amount
                    </label>
                    <div className="fs-14 font-weight-bold capitalize">
                      {orderDetails?.mayPharmacy.totalPrice < 1
                        ? "N/A"
                        : "$" + orderDetails?.mayPharmacy.totalPrice}
                    </div>
                  </div>
                  <div className="">
                    <label htmlFor="" className="fs-12 mb-0">
                      Doctor Phone Number
                    </label>
                    <div className="fs-14 font-weight-bold capitalize">{`${orderDetails?.doctor?.countryCode} ${orderDetails?.doctor?.phoneNumber}`}</div>
                  </div>
                  <div className="">
                    <label htmlFor="" className="fs-12 mb-0">
                      User Phone Number
                    </label>
                    <div className="fs-14 font-weight-bold capitalize">
                      {orderDetails?.bookingFor === "familyMember"
                        ? `+${orderDetails?.member?.countryCode} ${orderDetails?.member?.phoneNumber}`
                        : `+${orderDetails?.user?.countryCode} ${orderDetails?.user?.phoneNumber}`}
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          )}
          <div className="col-12 col-md-7 flex-grow-1">
            {orderDetails?.type !== "offline" &&
              (orderDetails?.status == "Accepted" ||
                orderDetails?.status == "Completed") &&
              orderDetails?.isAccept && (
                <div className="d-flex align-items-center w-100 gap-2">
                  <div className="d-flex w-100 ">
                    <Card className="p-3  flex-grow-1">
                      <div className="d-flex align-items-center justify-content-between">
                        <h4>Order Status</h4>
                        <Select
                          className="flex-grow-1 order-status-select pharma-card_distance"
                          options={filteredOrderStatus}
                          id="status-select"
                          placeholder="Update Status"
                          onChange={(value) => handleStatusChange(value)}
                        />
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <OrderStatusStepper
                          currentStatusArray={getStatusValues(
                            orderDetails?.orderStatus
                          )}
                          method={orderDetails?.method}
                        />
                      </div>
                    </Card>
                  </div>
                </div>
              )}
            <div className="d-flex w-100 ">
              <Card className="p-3  flex-grow-1">
                <div className="d-flex align-items-center justify-content-between">
                  <h4>My Pharma</h4>
                  {orderDetails?.mayPharmacy?.status === "Accepted" && (
                    <button className="btn btn-success p-2 px-3 fs-12 mr-2">
                      {orderDetails?.mayPharmacy?.status}
                    </button>
                  )}
                  {orderDetails?.mayPharmacy?.status === "Rejected" && (
                    <button className="btn bg-danger text-white p-2 px-3 fs-12">
                      {orderDetails?.mayPharmacy?.status}
                    </button>
                  )}
                </div>
                {orderDetails?.mayPharmacy?.invoice.length > 0 &&
                  orderDetails?.mayPharmacy?.invoice.map((item, index) => {
                    return (
                      <div
                        key={item._id}
                        className="d-flex align-items-start flex-column mt-3"
                      >
                        <div className="pharma-card_distance mb-2">
                          <span className="font-weight-bold">{index + 1}.</span>{" "}
                          <span>{item.medicineName}</span> ${item.price}
                        </div>
                      </div>
                    );
                  })}
              </Card>
            </div>
            <div className="d-flex w-100">
              <Card className="p-3  flex-grow-1">
                <h4>Pharmacy</h4>
                {orderDetails?.prescriptions &&
                  orderDetails?.prescriptions.map((item, index) => (
                    <div
                      key={item._id}
                      className="d-flex align-items-start flex-column"
                    >
                      <div className="pharma-card_distance">
                        <span className="font-weight-bold">{index + 1}.</span>{" "}
                        {item.name}
                      </div>
                      <p
                        className="pharma-card_description"
                        style={{ marginLeft: "10px" }}
                      >
                        <span className="font-weight-bold">Description</span>{" "}
                        {item.description}
                      </p>
                    </div>
                  ))}
              </Card>
            </div>
            {orderDetails?.method !== "offline" && (
              <div className="d-flex w-100">
                <Card className="p-3  flex-grow-1">
                  <div className="d-flex align-items-center justify-content-between">
                    <h4>Address</h4>
                  </div>
                  <div className="pharma-card_distance mt-3s">
                    <div className="d-flex align-items-center ">
                      <span>Address:</span>
                      <span className="ml-2">{orderDetails?.address}</span>
                    </div>
                    <div className="d-flex align-items-center ">
                      <span>City:</span>
                      <span className="ml-2">{orderDetails?.city}</span>
                    </div>
                    <div className="d-flex align-items-center ">
                      <span>Contact:</span>
                      <span className="ml-2">
                        {`+${orderDetails?.countryCode}` +
                          " " +
                          orderDetails?.phoneNumber}
                      </span>
                    </div>
                  </div>
                </Card>
              </div>
            )}
          </div>
        </div>
      )}
      {isLoading && (
        <div className="w-100 h-50 d-flex align-items-center justify-content-center">
          <Spinner animation="border" variant="info" />
        </div>
      )}
    </>
  );
}

export default OrderDetails;
