import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import Select from "react-select";
import {
  login,
  saveTokenInLocalStorage,
  signUp,
  signUpDoc,
  signUpLab,
  signUpPatient,
} from "../../services/AuthService";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import toast from "react-hot-toast";

const zodValidationSchema = z.object({
  email: z.string().trim().min(1, { message: "Email is required" }).email({
    message: "Must be a valid email",
  }),
  password: z
    .string()
    .trim()
    .min(6, { message: "Password must be at least 6 characters" }),
  phoneNumber: z
    .string()
    .min(6, { message: "Phone number must be at least 6 characters" })
    .refine((val) => !isNaN(val) && parseInt(val) > 0, {
      message: "Phone number must be a positive number"
    }),
  // countryCode: z.string().trim().min(1, { message: "Country code is requird" }),
  // labName: z.string().trim().min(1, { message: "Lab name is requird" }),
  name: z.string().trim().min(1, { message: " Name is requird" }),
  // pharmacyName: z.string().nullable(),
  // labName: z.string().nullable(),

  startTime: z.string().trim().min(1, { message: "Start time is requird" }),
  endTime: z.string().trim().min(1, { message: "End time is requird" }),
});
function Register(props) {
  const [selectedOption, setSelectedOption] = useState({
    value: "Pharmacy",
    label: "Pharmacy",
  });
  const [labName, setLabName] = useState("");
  const [code, setCode] = useState("+1");

  const [googleAddress, setGoogleAddress] = useState("");

  const [errorAddress, setErrorAddress] = useState("");
  const [apiError, setApiError] = useState("");

  const [locationDetails, setLocationDetails] = useState({
    lat: null,
    lng: null,
    label: "",
  });
  const options = [
    { value: "Pharmacy", label: "Pharmacy" },
    { value: "Lab", label: "Lab" },
  ];
  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const handleAddress = async (place) => {
    try {
      setGoogleAddress(place.label);
      const results = await geocodeByAddress(place.label);
      console.log(results, "results");

      const latLng = await getLatLng(results[0]);

      const newLocationDetails = {
        lat: latLng.lat,
        lng: latLng.lng,
        label: place.label,
      };

      setLocationDetails(newLocationDetails);
    } catch (error) {
      console.error("Error getting address details:", error);
    }
  };

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(zodValidationSchema),
  });
  // useEffect(() => {
  //   if (googleAddress === "") {
  //     setErrorAddress("Address is required");
  //   } else {
  //     setErrorAddress("");
  //   }
  // }, [googleAddress]);
  // const onSubmit = (data) => {
  //   console.log(googleAddress, "data");
  //   if (googleAddress === "") {
  //     setErrorAddress("Address is required");
  //   } else {
  //     setErrorAddress("");
  //   }
  //   console.log(data, "aaaa");
  //   console.log(locationDetails, "bbbb");
  //   console.log(code, "cccc");
  //   console.log(labName, "dddd");
  //   console.log(selectedOption, "eeee");
  //   // login(data)
  //   //   .then((response) => {
  //   //     saveTokenInLocalStorage(response.data.value.token);
  //   //     toast.success("Login Successfully!");
  //   //     props.history.push("/");
  //   //   })
  //   //   .catch((error) => {
  //   //     console.log(error.response.data.message, "Login error");
  //   //     setApiError(error.response.data.message);
  //   //   });
  // };

  const onSubmit = (data) => {
    console.log("first", data);
    if (googleAddress === "") {
      setErrorAddress("Address is required");
      return;
    } else {
      setErrorAddress("");
    }
    const {
      email,
      phoneNumber,
      password,
      name,
      pharmacyName,
      startTime,
      endTime,
    } = data;

    // Creating the object
    const formData = {
      email: email,
      phoneNumber: phoneNumber,
      countryCode: code, // Assuming 'code' is the country code extracted from the phone input
      password: password,
      type: selectedOption.value,
      pharmacyName:
        selectedOption.value === "Pharmacy" ? pharmacyName : undefined,
      labName: selectedOption.value === "Lab" ? labName : undefined,
      openingHours: {
        startTime: startTime,
        closeTime: endTime,
      },
      lat: locationDetails.lat,
      long: locationDetails.lng,
      address: locationDetails.label,
    };

    console.log(formData, "llll");
    // Now 'formData' contains the desired object structure
    signUpLab(formData)
      .then((response) => {
        // saveTokenInLocalStorage(response.data.value.token);
        toast.success("Sign up Successfully!");
        props.history.push("/");
      })
      .catch((error) => {
        console.log(error.response.data.message, "Login error");
        setApiError(error.response.data.message);
      });
  };

  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <h4 className="text-center mb-4 ">Create account</h4>
                    {apiError && (
                      <div
                        role="alert"
                        className="fade alert-dismissible fade show alert alert-danger show"
                      >
                        {apiError}
                      </div>
                    )}
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="form-group">
                        <label className="mb-1 mt-3 ">
                          <strong>Full Name</strong>
                        </label>
                        <input
                          id="name"
                          type="text"
                          placeholder="Enter full name"
                          className="form-control"
                          {...register("name")}
                        />
                      </div>
                      {errors.name && (
                        <div className="text-danger fs-12">
                          {errors.name?.message}
                        </div>
                      )}

                      <div className="form-group">
                        <label className="mb-1 ">
                          <strong>Email</strong>
                        </label>
                        <input
                          id="email"
                          type="email"
                          placeholder="Enter email"
                          className="form-control"
                          {...register("email")}
                        />
                        {errors.email && (
                          <div className="text-danger fs-12">
                            {errors.email?.message}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label className="mb-1 ">
                          <strong>Password</strong>
                        </label>
                        <input
                          id="password"
                          type="password"
                          placeholder="Enter password"
                          className="form-control"
                          {...register("password")}
                        />
                        {errors.password && (
                          <div className="text-danger fs-12">
                            {errors.password?.message}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label className="mb-2 ">
                          <strong> Select User Type </strong>
                        </label>
                        <Select
                          id="select"
                          // placeholder="Select User Type"
                          value={selectedOption}
                          onChange={handleSelectChange}
                          options={options}
                          isSearchable={false}
                          styles={{
                            placeholder: (provided) => ({
                              ...provided,
                              color: "gray",
                            }),
                          }}
                          placeholder="Select user type"
                        />
                      </div>
                      {selectedOption.value === "Lab" ? (
                        <>
                          {" "}
                          <div className="form-group">
                            <label className="mb-1 mt-3 ">
                              <strong>Lab Name</strong>
                            </label>
                            <input
                              id="labName"
                              type="text"
                              className="form-control"
                              placeholder="Enter lab name"
                              // {...register("labName")}
                              onChange={(e) => setLabName(e.target.value)}
                              required
                            />
                            {/* {errors.labName && (
                              <div className="text-danger fs-12">
                                {errors.labName?.message}
                              </div>
                            )} */}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="form-group">
                            <label className="mb-1 mt-3 ">
                              <strong>Pharmacy Name</strong>
                            </label>
                            <input
                              id="pharmacyName"
                              type="text"
                              className="form-control"
                              placeholder="Enter pharmacy name"
                              {...register("pharmacyName")}
                              required
                            />
                            {errors.pharmacyName && (
                              <div className="text-danger fs-12">
                                {errors.pharmacyName?.message}
                              </div>
                            )}
                          </div>
                        </>
                      )}

                      <div className="form-group">
                        <label className="mb-1 mt-3 ">
                          <strong>Address</strong>
                        </label>
                        <GooglePlacesAutocomplete
                          className="form-control"
                          // apiKey="AIzaSyBQD3kW3XrWQm8Q89Z0dQSSpEfMD5K0frE"
                          apiKey={"AIzaSyA_kz_OxgMPwWS7AEWRVUHSLSvqYFlgZAc"}
                          selectProps={{
                            placeholder: "Enter Address",
                            styles: {
                              // Customize the style for the input
                              // input: {
                              //   outline: "none", // Remove the outline on focus
                              //   color: "green", // Change the text color
                              //   placeholder: {
                              //     color: "red", // Change the placeholder color
                              //   },
                              // },
                            },
                            // value: googleAddress,
                            value: {
                              label: googleAddress,
                              value: googleAddress,
                            },

                            onChange: (place) => {
                              handleAddress(place);
                            },
                          }}
                        />
                        {errorAddress && (
                          <div className="text-danger fs-12">
                            {errorAddress}
                          </div>
                        )}
                      </div>

                      <div className="form-group">
                        <label className="mb-1 mt-3 ">
                          <strong>Phone number</strong>
                        </label>
                        <div className="d-flex">
                          <div className="relative">
                            <PhoneInput
                              country={"us"}
                              enableSearch={true}
                              value={code}
                              // value="+1"
                              onChange={(phone) => setCode(phone)}
                            />
                          </div>

                          <div className="w-100">
                            <input
                              id="phoneNumber"
                              type="number"
                              className="form-control  sign-phone"
                              placeholder="Enter Phone number "
                              {...register("phoneNumber")}
                            />
                          </div>
                        </div>
                        {errors.phoneNumber && (
                          <div className="text-danger fs-12">
                            {errors.phoneNumber?.message}
                          </div>
                        )}
                      </div>

                      <div className="form-group">
                        <label className="mb-1 mt-3 ">
                          <strong>Opening Hours</strong>
                        </label>
                        <div className="d-flex" style={{justifyContent:"space-between"}}>
                          <div className="mr-3" style={{width:"100%"}}>
                            <input
                              type="time"
                              className="form-control"
                              {...register("startTime")}
                            />
                            {errors.startTime && (
                              <div className="text-danger fs-12">
                                {errors.startTime?.message}
                              </div>
                            )}
                          </div>
                          <div style={{width:"100%"}}>
                            <input
                              type="time"
                              className="form-control"
                              {...register("endTime")}
                            />
                            {errors.endTime && (
                              <div className="text-danger fs-12">
                                {errors.endTime?.message}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="text-center mt-4">
                        <input
                          type="submit"
                          className="btn btn-primary btn-block"
                        />
                      </div>
                    </form>
                    <div className="new-account mt-3 ">
                      <p>
                        Already have an account?{" "}
                        <Link className="text-primary" to="/login">
                          Sign in
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(Register);
