import React, { useState, useRef, useEffect } from "react";
import { Card, Col, Modal, Button } from "react-bootstrap";
import toast from "react-hot-toast";
import { HiOutlineTrash } from "react-icons/hi";
import { getInventoryModal } from "../../../services/AsyncFunctions";

function CreateInvoicePharma({ onHide, show, testData, setTestData }) {
  const [description, setDescription] = useState("");
  const [quantity, setQuantity] = useState("1");
  const [price, setPrice] = useState("");
  const [priceItem, setPriceItem] = useState("");
  const [medicineId, setMedicineId] = useState("");
  const [inventoryData, setInvetoryData] = useState([]);
  const [medicineName, setMedicineName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const dropdownRef = useRef(null);
  const [loading, setLoading] = useState(true);

  async function submit() {
    onHide();
  }

  function addTestoInvoiceList(e) {
    e.preventDefault();
    if (selectedItem == null) {
      toast.error("Medicine name is required");
      return;
    } else if (!description) {
      toast.error("Description is required");
      return;
    } else if (
      !quantity ||
      quantity <= 0 ||
      !Number.isInteger(Number(quantity))
    ) {
      toast.error("Quantity is required");
      return;
    } else if (!price) {
      toast.error("Price is required");
      return;
    } else {
      if (testData.length == 0) {
        setTestData([
          { medicineName, description, quantity, price, medicineId },
        ]);
      } else {
        setTestData((prev) => [
          ...prev,
          { medicineName, description, quantity, price, medicineId },
        ]);
      }
      setMedicineName("");
      setDescription("");
      setPrice("");
      setQuantity("1");
      setInvetoryData([]);
      setSelectedItem(null);
    }
  }
  
  function removeMedicineFromList(ItemIndex) {
    const tempList = testData.filter((item, index) => ItemIndex !== index);
    setTestData([...tempList]);
  }

  const filteredItems = inventoryData.filter((item) =>
    item.medicineName.toLowerCase().includes(medicineName.toLowerCase())
  );

  const handleInputChange = (e) => {
    setMedicineName(e.target.value || "");
    if (!isOpen) setIsOpen(true);
  };

  const handleSelectItem = (item) => {
    setSelectedItem(item);
    setMedicineName(item.medicineName);
    setPrice(item.costPerUnit);
    setPriceItem(item.costPerUnit);
    setMedicineId(item._id);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  async function fetchInventory() {
    setLoading(true);
    try {
      const result = medicineName.replace(/&/g, "");
      const response = await getInventoryModal(result, 0);
      setInvetoryData(response.data.data.medicines);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (medicineName) {
      const delayDebounceFn = setTimeout(() => {
        fetchInventory();
      }, 100);
      return () => clearTimeout(delayDebounceFn);
    } else {
      fetchInventory();
    }
  }, [medicineName]);

  useEffect(() => {
    if (price && medicineName && quantity) {
      setPrice(priceItem * quantity);
    } else if (!quantity) {
      setPrice(priceItem);
    }
  }, [quantity, medicineName]);

  return (
    <Modal
      className="modal fade"
      size="lg"
      centered
      onHide={onHide}
      show={show}
    >
      <Col>
        <Card>
          <Card.Header className="d-block">
            <div className="d-flex justify-content-between align-items-center">
              <h3>Create Invoice</h3>
              <Button variant="" className="close" onClick={() => onHide()}>
                <span style={{ fontSize: "30px" }}>&times;</span>
              </Button>
            </div>
          </Card.Header>

          <Card.Body>
            <form
              onSubmit={addTestoInvoiceList}
              className="d-flex align-items-start flex-column gap-2"
            >
              <div
                ref={dropdownRef}
                className="relative mt-10"
                style={{ width: "100%" }}
              >
                <div className="position-relative">
                  <input
                    type="text"
                    value={medicineName}
                    onChange={handleInputChange}
                    onClick={() => setIsOpen(true)}
                    placeholder="Select Medicine"
                    className="form-control mr-2"
                  />
                  {selectedItem && (
                    <button
                      type="button"
                      className="btn btn-link position-absolute"
                      style={{
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        padding: "0",
                        border: "none",
                        background: "none",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedItem(null);
                        setMedicineName("");
                        setPrice("");
                        setMedicineId("");
                      }}
                    >
                      <span style={{ fontSize: "20px" }}>&times;</span>
                    </button>
                  )}
                </div>
                {isOpen && (
                  <ul
                    className="absolute z-10 w-full bg-white border rounded-lg shadow-lg"
                    style={{
                      maxHeight: "280px",
                      overflowY: "auto",
                      scrollbarWidth: "thin",
                      scrollbarColor: "#888 #f1f1f1",
                      marginTop: "4px",
                    }}
                  >
                    <style>
                      {`
                        ul::-webkit-scrollbar {
                          width: 6px;
                        }
                        ul::-webkit-scrollbar-track {
                          background: #f1f1f1;
                          border-radius: 3px;
                        }
                        ul::-webkit-scrollbar-thumb {
                          background: #888;
                          border-radius: 3px;
                        }
                        ul::-webkit-scrollbar-thumb:hover {
                          background: #555;
                        }
                        .dropdown-item:hover {
                          background-color: #f8f9fa;
                          transition: all 0.2s ease;
                        }
                      `}
                    </style>
                    {inventoryData.length > 0 ? (
                      filteredItems.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => handleSelectItem(item)}
                          className="dropdown-item px-4 border-b last:border-b-0"
                          style={{
                            padding: "12px 16px",
                            cursor: "pointer",
                          }}
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <span
                                className="font-weight-medium"
                                style={{ color: "#2c3e50" }}
                              >
                                {item.medicineName}
                              </span>
                              <div className="text-muted small">
                                ID: {item._id.slice(-6)}
                              </div>
                            </div>
                            <div
                              className="text-primary font-weight-bold"
                              style={{
                                backgroundColor: "#e8f4ff",
                                padding: "4px 12px",
                                borderRadius: "16px",
                                fontSize: "14px",
                              }}
                            >
                              ${item.costPerUnit}
                            </div>
                          </div>
                        </li>
                      ))
                    ) : (
                      <li className="px-4 py-3 text-muted text-center">
                        No results found
                      </li>
                    )}
                  </ul>
                )}
              </div>
              <input
                type="text"
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="form-control mr-2"
                name="description"
              />
              <input
                type="number"
                placeholder="Quantity"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                className="form-control mr-2"
                name="medicineName"
                min="1"
              />
              <input
                type="text"
                placeholder="Price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                className="form-control mr-2"
                name="price"
              />
              <button className="btn btn-outline-primary" type="submit">
                Add
              </button>
            </form>
            {testData.length > 0 && (
              <ol className="">
                <div className="row mt-3">
                  <div className="col-4  pharma-card_distance invoice-table-header">
                    Medicine Name
                  </div>
                  <div className="col-4  pharma-card_distance invoice-table-header">
                    Quantity
                  </div>
                  <div className="col-2 pharma-card_distance invoice-table-header">
                    Price
                  </div>
                  <div className="col-2 pharma-card_distance invoice-table-header">
                    Action
                  </div>
                </div>
                {testData.map((item, index) => (
                  <div key={index} className="row mt-3">
                    <div className="col-4 pharma-card_distance">
                      <span className="font-weight-bold">{index + 1}.</span>{" "}
                      {item.medicineName}
                    </div>
                    <div className=" col-4 pharma-card_distance d-flex align-items-center">
                      <span className="">{item.quantity}</span>
                    </div>
                    <div className=" col-2 pharma-card_distance d-flex align-items-center">
                      <span className="">{item.price}</span>
                    </div>
                    <div
                      className=" col-2 pharma-card_distance d-flex align-items-center"
                      onClick={() => removeMedicineFromList(index)}
                    >
                      <HiOutlineTrash
                        size={"25px"}
                        color="red"
                        className="ml-2 pointer"
                      />
                    </div>
                  </div>
                ))}
              </ol>
            )}
          </Card.Body>
          <Card.Footer>
            <button className="btn btn-primary w-100" onClick={submit}>
              Add Invoice
            </button>
          </Card.Footer>
        </Card>
      </Col>
    </Modal>
  );
}

export default CreateInvoicePharma;
